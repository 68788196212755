import classes from "./CardDescription.module.css";

import Arrow0 from "../../images/Arrow_0.png";
import Arrow1 from "../../images/Arrow_1.png";
import Arrow2 from "../../images/Arrow_2.png";
import Arrow3 from "../../images/Arrow_3.png";

interface Props {
  title: string;
  description: string;
  index: number;
  useLargerFont?: boolean;
  usecustomStyle?: boolean;
  customWidth?: string;
  customMargin?: number;
  showIcon?: boolean;
  valueIcon?: number;
  showDescriptionInList?: boolean;
  color?: string;
}

const CardDescription = ({
  title,
  description,
  index,
  useLargerFont,
  usecustomStyle,
  customWidth,
  customMargin,
  showIcon,
  valueIcon,
  showDescriptionInList,
  color,
}: Props): JSX.Element => {
  let iconSource: string | undefined;
  let listItems: string[] = [];

  const iconSourceHandler = (valueIcon: number | undefined) => {
    if (showIcon) {
      switch (valueIcon) {
        case 1:
          iconSource = Arrow1;
          break;
        case 2:
          iconSource = Arrow2;
          break;
        case 3:
          iconSource = Arrow3;
          break;
        default:
          iconSource = Arrow0;
      }
    }
    return iconSource;
  };

  const titleTypeHandler = (): {} => {
    let titleType = {};
    if (useLargerFont) {
      titleType = { fontSize: "18px", lineHeight: "30px" };
    }
    if (usecustomStyle) {
      titleType = { fontFamily: "AktivGroteskEx_A_Md", fontSize: "12px" };
    }
    return titleType;
  };

  const extractListItems = (str: string) => {
    // Use a regular expression to match the content inside <li> tags
    const regex = /<li>(.*?)<\/li>/g;
    let match;
    const items = [];

    // Loop through all matches
    while ((match = regex.exec(str)) !== null) {
      items.push(match[1]);
    }

    return items;
  };
  if (showDescriptionInList) {
    listItems = extractListItems(description);
  }

  return (
    <div
      className={classes.sub}
      style={{ width: customWidth, margin: customMargin }}
    >
      <div className={classes.inner}>
        <div className={classes.title} style={titleTypeHandler()}>
          {title}
        </div>
        {showIcon && (
          <div className={classes.icon}>
            <img src={iconSourceHandler(valueIcon)} alt="icon" />
          </div>
        )}
      </div>
      <div
        className={classes.subText}
        style={useLargerFont ? { fontSize: "11px", lineHeight: "14px" } : {}}
      >
        {showDescriptionInList ? (
          <div>
            {listItems.map((item, index) => (
              <div key={index} className={classes.listItem}>
                <div
                  className={classes.point}
                  style={{ backgroundColor: color || "var(--primary1000)" }}
                ></div>
                <div
                  className={classes.subText}
                  style={{ marginBottom: 0, flex: 1 }}
                >
                  {item}
                </div>
              </div>
            ))}
          </div>
        ) : (
          description
        )}
      </div>
    </div>
  );
};

export default CardDescription;
