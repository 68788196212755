import classes from "./ServiceSection.module.css";
import CustomCard from "../../components/reusable/CustomCard";
import listIcon from "../../images/listIcon.png";

type Props = {
  data: any;
  type: string;
};

const ServiceSection = ({ data, type }: Props) => {
  let rowStyle, columnStyle;

  const getRowStyle = (row: number) => {
    switch (row) {
      case 1:
        rowStyle = {
          fontFamily: "AktivGroteskEx_A_Bd",
          minHeight: "32px",
          fontSize: "14px",
          borderBottom: "3px solid var(--primary100)",
        };
        break;
      default:
        rowStyle = {
          fontFamily: "AktivGroteskEx_A_Rg",
          minHeight: "23px",
          fontSize: "10px",
        };
    }
    return rowStyle;
  };

  const getColumnStyle = (column: number) => {
    if (type === "UserService") {
      switch (column) {
        case 3:
          columnStyle = {
            flex: 2.7,
          };
          break;
        case 4:
          columnStyle = {
            flex: 2.1,
          };
          break;
        default:
          columnStyle = { flex: 1.2 };
      }
      return columnStyle;
    }
    switch (column) {
      case 2:
      case 3:
        columnStyle = {
          flex: 1,
        };
        break;
      default:
        columnStyle = { flex: 2 };
    }
    return columnStyle;
  };

  let dataTable: any[] = [];

  if (type === "UserService") {
    dataTable = [
      { INDEX: 1, LABEL: data.INTENSITY_LABEL, VALUE: data.INTENSITY },
      { INDEX: 2, LABEL: data.DURATION_LABEL, VALUE: data.DURATION },
      {
        INDEX: 3,
        LABEL: data.SESSIONS_PER_WEEK_LABEL,
        VALUE: data.SESSIONS_PER_WEEK,
      },
      {
        INDEX: 4,
        LABEL: data.CYCLING_POWER_LABEL,
        VALUE: data.CYCLING_POWER,
      },
      { INDEX: 5, LABEL: data.ALTITUDE_LABEL, VALUE: data.ALTITUDE },
    ];
  } else if (type === "restoreCustomReport") {
    dataTable = [
      {
        INDEX: 1,
        LABEL: data.ACTIVE_INGREDIENT_LABEL,
        VALUE: data.ACTIVE_INGREDIENT,
      },
    ];
  } else {
    dataTable = [
      {
        INDEX: 1,
        LABEL: data.ACTIVE_INGREDIENT_LABEL,
        VALUE: data.ACTIVE_INGREDIENT,
      },
      { INDEX: 2, LABEL: data.DOSE_LABEL, VALUE: data.DOSE },
      { INDEX: 3, LABEL: data.DURATION_LABEL, VALUE: data.DURATION },
      {
        INDEX: 4,
        LABEL: data.SESSIONS_PER_WEEK_LABEL,
        VALUE: data.SESSIONS_PER_WEEK,
      },
    ];
  }

  const filterDataTable = dataTable.filter(
    (el: any) => el.VALUE !== "0" && el.VALUE !== "" && el.VALUE !== null
  );

  return (
    <div className={classes.container} key={`${Math.random()}`}>
      <div className={classes.title}>{data.TITLE}</div>
      <div className={classes.inner}>
        <div className={classes.imgContainer}>
          <img
            className={classes.image}
            src={type === "IVService" ? data.IMAGE_URL : data.IMAGESMALL_URL}
            alt="section img"
          />
        </div>

        <div className={classes.info}>
          <CustomCard
            cardPadding="0"
            cardMargin="0"
            cardWidth={
              type === "UserService" && filterDataTable.length <= 3
                ? "70%"
                : "100%"
            }
          >
            <ul className={classes.rowItems}>
              <li className={classes.rowItem} style={getRowStyle(1)}>
                {filterDataTable.length > 0 && (
                  <ul className={classes.cellItem}>
                    {filterDataTable.map((cell: any) => (
                      <li
                        className={classes.cell}
                        key={cell.INDEX}
                        style={getColumnStyle(cell.INDEX)}
                      >
                        {cell.LABEL}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            </ul>
            <ul className={classes.rowItems}>
              <li className={classes.rowItem} style={getRowStyle(2)}>
                {filterDataTable.length > 0 && (
                  <ul className={classes.cellItem}>
                    {filterDataTable.map((cell: any) => (
                      <li
                        className={classes.cell}
                        key={cell.INDEX}
                        style={getColumnStyle(cell.INDEX)}
                      >
                        {cell.VALUE}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            </ul>
          </CustomCard>

          <ul className={classes.tabs}>
            <div className={classes.tab}>
              <div
                className={classes.tabTitle}
                style={{ color: "var(--secondary21)" }}
              >
                {data.PNOE_BENEFITS_LABEL}
              </div>
              <ul className={classes.tabItems}>
                {data.PNOE_BENEFITS.map((listItem: any) => (
                  <li key={listItem.INDEX} className={classes.description}>
                    <img
                      className={classes.listIcon}
                      src={listIcon}
                      alt="list icon"
                    />
                    <div className={classes.listText}>{listItem.VALUE}</div>
                  </li>
                ))}
              </ul>
            </div>
            <div className={classes.tab}>
              <div className={classes.tabTitle}>
                {data.ADDITIONAL_BENEFITS_LABEL}
              </div>
              <ul className={classes.tabItems}>
                {data.ADDITIONAL_BENEFITS.map((listItem: any) => (
                  <li key={listItem.INDEX} className={classes.description}>
                    <img
                      className={classes.listIcon}
                      src={listIcon}
                      alt="list icon"
                    />
                    <div className={classes.listText}>{listItem.VALUE}</div>
                  </li>
                ))}
              </ul>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ServiceSection;
