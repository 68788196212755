import React, { useEffect, useState } from "react";
import validator from "validator";

import X_icon from "../../../common/svgs/X_icon";
import Success_icon from "../../../common/svgs/Success_icon";

import classes from "./PasswordValidator.module.css";

type Props = {
  curPassword: string;
};
const PasswordValidator = ({ curPassword }: Props) => {
  const validateCorrectLength = (value: string) => {
    return (
      value.length <= 20 &&
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 0,
        minUppercase: 0,
        minNumbers: 0,
        minSymbols: 0,
      })
    );
  };

  const validateMinUppercase = (value: string) => {
    return validator.isStrongPassword(value, {
      minLength: 0,
      minLowercase: 0,
      minUppercase: 1,
      minNumbers: 0,
      minSymbols: 0,
    });
  };

  const validateMinLowercase = (value: string) => {
    return validator.isStrongPassword(value, {
      minLength: 0,
      minLowercase: 1,
      minUppercase: 0,
      minNumbers: 0,
      minSymbols: 0,
    });
  };

  const validateMinNumber = (value: string) => {
    return validator.isStrongPassword(value, {
      minLength: 0,
      minLowercase: 0,
      minUppercase: 0,
      minNumbers: 1,
      minSymbols: 0,
    });
  };

  const validateMinSymbol = (value: string) => {
    return validator.isStrongPassword(value, {
      minLength: 0,
      minLowercase: 0,
      minUppercase: 0,
      minNumbers: 0,
      minSymbols: 1,
    });
  };

  return (
    <div>
      <div className={classes.title}>Your Password must contain</div>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          {validateCorrectLength(curPassword) ? <Success_icon /> : <X_icon />}
          <div>Between 8 and 20 characters</div>
        </li>
        <li className={classes.listItem}>
          {validateMinUppercase(curPassword) ? <Success_icon /> : <X_icon />}{" "}
          <div>One upper case letter</div>
        </li>
        <li className={classes.listItem}>
          {validateMinLowercase(curPassword) ? <Success_icon /> : <X_icon />}{" "}
          <div>One lower case letter</div>
        </li>
        <li className={classes.listItem}>
          {validateMinSymbol(curPassword) ? <Success_icon /> : <X_icon />}{" "}
          <div>One special character</div>
        </li>
        <li className={classes.listItem}>
          {validateMinNumber(curPassword) ? <Success_icon /> : <X_icon />}{" "}
          <div>One number</div>
        </li>
      </ul>
    </div>
  );
};

export default PasswordValidator;
