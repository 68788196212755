import classes from "./ProgressBar.module.css";
import { useState } from "react";
import { useSelector } from "react-redux";

const ProgressBar = ({ value, curStep, totalSteps, prevStep }) => {
  const [showText, setShowText] = useState(false);
  const stepper = useSelector((state) => state.stepper.step);

  const handleMouseEnter = (e) => {
    setShowText(true);
  };
  const handleMouseLeave = (e) => {
    setShowText(false);
  };

  return (
    <div
      className={classes.container}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={classes.stepper}>
        {stepper !== 1 && (
          <button
            className={classes.iconContainer}
            onClick={prevStep}
            disabled={curStep === 1}
          >
            <svg
              width="12"
              height="18"
              viewBox="0 0 12 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={classes.icon}
            >
              <path
                d="M9.37464 17.4375C8.92712 17.4374 8.49797 17.2595 8.18158 16.943L1.43158 10.193C1.11522 9.87659 0.9375 9.44745 0.9375 8.99998C0.9375 8.55252 1.11522 8.12337 1.43158 7.80692L8.18158 1.05692C8.33724 0.895748 8.52345 0.767191 8.72933 0.678751C8.93521 0.590311 9.15665 0.543759 9.38071 0.541812C9.60478 0.539865 9.82699 0.582561 10.0344 0.66741C10.2418 0.752259 10.4302 0.877561 10.5886 1.03601C10.7471 1.19445 10.8724 1.38286 10.9572 1.59025C11.0421 1.79764 11.0848 2.01985 11.0828 2.24391C11.0809 2.46798 11.0343 2.68941 10.9459 2.89529C10.8574 3.10117 10.7289 3.28738 10.5677 3.44305L5.01076 8.99998L10.5677 14.5569C10.8036 14.7929 10.9643 15.0936 11.0294 15.4209C11.0945 15.7482 11.061 16.0874 10.9333 16.3957C10.8057 16.704 10.5894 16.9675 10.312 17.153C10.0345 17.3384 9.70834 17.4374 9.37464 17.4375Z"
                fill="#E6F1FF"
              />
            </svg>
          </button>
        )}
        <div className={classes.header}>
          <div>
            {curStep}/{totalSteps <= 10 ? totalSteps : totalSteps - 1}
          </div>
          <div className={classes.titleMobile}>Biometrics</div>
        </div>
      </div>
      <div className={classes.title}>Biometrics</div>
      <div className={classes.bar}>
        <div className={classes.inner}>
          <div className={classes.fill} style={{ width: `${value}%` }}></div>
        </div>
        <div className={classes.pointers}>
          <div className={`${classes.pointer} ${classes.point__q1}`}></div>
          <div className={`${classes.pointer} ${classes.point__q2}`}></div>
          <div className={`${classes.pointer} ${classes.point__q3}`}></div>
          <div className={`${classes.pointer} ${classes.point__q4}`}></div>
          <div className={`${classes.pointer} ${classes.point__q5}`}></div>
          <div className={`${classes.pointer} ${classes.point__q6}`}></div>
          <div className={`${classes.pointer} ${classes.point__q7}`}></div>
          <div className={`${classes.pointer} ${classes.point__q8}`}></div>
          <div className={`${classes.pointer} ${classes.point__q9}`}></div>
          <div className={`${classes.pointer} ${classes.point__q10}`}></div>
          <div className={`${classes.pointer} ${classes.point__q11}`}></div>
          <div className={`${classes.pointer} ${classes.point__q12}`}></div>
          <div className={`${classes.pointer} ${classes.point__q13}`}></div>
          <div className={`${classes.pointer} ${classes.point__q14}`}></div>
          <div className={`${classes.pointer} ${classes.point__q15}`}></div>
          <div className={`${classes.pointer} ${classes.point__q16}`}></div>
          <div className={`${classes.pointer} ${classes.point__q17}`}></div>
          <div className={`${classes.pointer} ${classes.point__q18}`}></div>
        </div>
        {showText && (
          <div className={classes.descriptionPanelContainer}>
            <div className={classes.descriptionPanel}>
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="8.48535"
                  width="12"
                  height="12"
                  transform="rotate(45 8.48535 0)"
                  fill="#1B1F22"
                />
              </svg>
            </div>
            <div className={classes.descriptionPanelText}>
              <span className={classes.text1}>{`${Math.floor(value)}%`}</span>{" "}
              <span className={classes.text2}>
                complete of the biometric questionnaire! Keep it up!
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProgressBar;
