import CoverPage from "../pages/CoverPage/CoverPage";
import PillarsOfLongevityPage from "../pages/PillarsOfLongevityPage/PillarsOfLongevityPage";
import OverviewMetricBarGraphPage from "../pages/OverviewMetricBarGraphPage/OverviewMetricBarGraphPage";
import CoreMetricsPage from "../pages/CoreMetricsPage/CoreMetricsPage";
import WellnessHealthHistoryPage from "../pages/WellnessHealthHistoryPage/WellnessHealthHistoryPage";
import MetricPage from "../pages/MetricPage/MetricPage";
import RiskMetricPage from "../pages/MetricPage/RiskMetricPage";
import CaloricBalancePage from "../pages/CaloricBalance/CaloricBalancePage";
import MacronutrientBalancePage from "../pages/MacronutrientBalancePage/MacronutrientBalancePage";
import TestingSchedulePage from "../pages/TestingSchedulePage/TestingSchedulePage";
import AuxiliaryTreatmentsPageV02 from "../pages/AuxiliaryTreatmentsPage_V02/AuxiliaryTreatmentsPageV02";
import UserServicesPage from "../pages/UserServicesPage/UserServicesPage";
import { useState } from "react";
import BiologicalAgePage from "../pages/BiologicalAgePageOld/BiologicalAgePage";

type Props = {
  data: any;
  svgConverter: (
    content: string,
    result: string,
    imgWidth: number
  ) => JSX.Element;
  reportType: string;
  isCreatingPDF: boolean;
};
const ReportRMR = ({
  data,
  svgConverter,
  reportType,
  isCreatingPDF,
}: Props) => {
  // console.log(data);
  const [imgBiologicalAgePage, setImgBiologicalAgePage] = useState(false);

  let wellnessHealthHistoryPageHasContext = false;
  for (
    let i = 0;
    i <
    data.PAGES.find((page: any) => page.PAGE_TYPE === "HEALTH_HISTORY").SECTIONS
      .length;
    i++
  ) {
    if (
      data.PAGES.find((page: any) => page.PAGE_TYPE === "HEALTH_HISTORY")
        .SECTIONS[i].TEXT !== null
    ) {
      wellnessHealthHistoryPageHasContext = true;
    }
  }

  // useEffect(() => {
  //   svgConverter("biologicalAgePage", "biologicalAgeImage", 794);
  //   setImgBiologicalAgePage(true);
  // }, [svgConverter]);

  // delete when deployed new VO2 max chart
  const biaxialLineChartDataHandler = () => {
    const biaxialLineChartData = {
      gender: data.GENDER,
      lines_men: [
        {
          x: 15,
          area0: [15, 37],
          area1: [37, 42],
          area2: [42, 47],
          area3: [47, 52],
          area4: [52, 65],
        },
        {
          x: 75,
          area0: [15, 21],
          area1: [21, 24],
          area2: [24, 27],
          area3: [27, 30],
          area4: [30, 65],
        },
        { x: data.AGE, lineUser: data.VO2PEAK },
      ],
      lines_women: [
        {
          x: 15,
          area0: [15, 33],
          area1: [33, 38],
          area2: [38, 42],
          area3: [42, 47],
          area4: [47, 65],
        },
        {
          x: 75,
          area0: [15, 17],
          area1: [17, 20],
          area2: [20, 23],
          area3: [23, 25],
          area4: [25, 65],
        },
        { x: data.AGE, lineUser: data.VO2PEAK },
      ],
    };
    return biaxialLineChartData;
  };

  // Flatten METRICS from ORGANS
  const flattenedMetricsArray = Object.values(
    data.PAGES.find((page: any) => page.PAGE_TYPE === "OVERVIEW").ORGANS
  ).flatMap((organ: any) => [...organ.METRICS]);

  // const imgBiologicalAge = (
  //   <div id="biologicalAgePage" className={classes.imgBiologicalAge}>
  //     <BiologicalAgePage
  //       data={data.PAGES.find((page) => page.PAGE_TYPE === "VO2MAX")}
  //       onSvgConverter={svgConverter}
  //       gender={data.GENDER}
  //       userAge={data.AGE}
  //       userVO2PEAK={data.VO2PEAK}
  //     />
  //   </div>
  // );

  {
    /*//New design for VO2 max chart*/
  }

  // useEffect(() => {
  //   svgConverter("biologicalAgePage", "biologicalAgeImage", 794);
  //   setImgBiologicalAgePage(true);
  // }, [svgConverter]);
  //
  // const imgBiologicalAge = (
  //   <div id="biologicalAgePage" className={classes.imgBiologicalAge}>
  //     <BiologicalAgePage
  //       data={data.PAGES.find((page) => page.PAGE_TYPE === "VO2MAX")}
  //       onSvgConverter={svgConverter}
  //       gender={data.GENDER}
  //       userAge={data.AGE}
  //       userVO2PEAK={data.VO2PEAK}
  //     />
  //   </div>
  // );

  return (
    <>
      <CoverPage
        data={data.PAGES.find((page: any) => (page.PAGE_TYPE = "COVER"))}
        trainerName={data.TRAINER}
        trainerMail={data.TRAINER_MAIL}
        reportType={reportType}
        isRestoreReport={data.IS_RESTORE}
      />
      <PillarsOfLongevityPage
        data={data.PAGES.find((page: any) => page.PAGE_TYPE === "PILLARS")}
        gender={data.GENDER}
        reportType={reportType}
        isRestoreReport={data.IS_RESTORE}
      />
      <OverviewMetricBarGraphPage
        data={data.PAGES.find((page: any) => page.PAGE_TYPE === "OVERVIEW")}
        onSvgConverter={svgConverter}
        reportType={
          reportType === "10" || reportType === "12" || reportType === "14"
            ? "isAMR"
            : "isRMR"
        }
        isCreatingPDF={isCreatingPDF}
      />
      {data.PAGES.find((page: any) => page.PAGE_TYPE === "INTRO") && (
        <CoreMetricsPage
          data={data.PAGES.find((page: any) => page.PAGE_TYPE === "INTRO")}
          reportType={reportType}
          isRestoreReport={data.IS_RESTORE}
        />
      )}
      {wellnessHealthHistoryPageHasContext && (
        <WellnessHealthHistoryPage
          data={data.PAGES.find(
            (page: any) => page.PAGE_TYPE === "HEALTH_HISTORY"
          )}
        />
      )}
      {/*{data.PAGES.find((page) => page.PAGE_TYPE === "VO2MAX") && (*/}
      {/*  <div*/}
      {/*    id="biologicalAgeImage"*/}
      {/*    style={*/}
      {/*      imgBiologicalAgePage && !allPagesToVertical*/}
      {/*        ? {*/}
      {/*            transform: "rotate(90deg)",*/}
      {/*            marginTop: "-166px",*/}
      {/*            marginBottom: "-165px",*/}
      {/*          }*/}
      {/*        : { height: "1122px" }*/}
      {/*    }*/}
      {/*  >*/}
      {/*    {!imgBiologicalAgePage && imgBiologicalAge}*/}
      {/*  </div>*/}
      {/*)}*/}
      {data.PAGES.find((page: any) => page.PAGE_TYPE === "BIOLOGICAL_AGE") && (
        <BiologicalAgePage
          data={data.PAGES.find(
            (page: any) => page.PAGE_TYPE === "BIOLOGICAL_AGE"
          )}
          onSvgConverter={svgConverter}
          biaxialLineChartData={biaxialLineChartDataHandler()}
          dataNew={
            data.PAGES.find((page: any) => page.PAGE_TYPE === "OVERVIEW").NAVBAR
          }
          showVO2MaxChart={false}
          isRestoreReport={data.IS_RESTORE}
        />
      )}
      {data.USERSERVICES === 1 &&
        data.PAGES.find((page: any) => page.PAGE_TYPE === "USERSERVICES") && (
          <UserServicesPage
            data={data.PAGES.find(
              (page: any) => page.PAGE_TYPE === "USERSERVICES"
            )}
            isRestoreReport={data.IS_RESTORE}
          />
        )}
      {flattenedMetricsArray.length > 0 && (
        <MetricPage
          data={flattenedMetricsArray}
          navBarData={
            data.PAGES.find((page: any) => page.PAGE_TYPE === "OVERVIEW").NAVBAR
          }
          qrCodeData={
            data.PAGES.find((page: any) => page.PAGE_TYPE === "OVERVIEW").QRCODE
          }
        />
      )}
      {data.PAGES.find((page: any) => page.PAGE_TYPE === "OVERVIEW").ORGANS
        .CELLS.RISK_METRICS.length > 0 && (
        <RiskMetricPage
          data={data.PAGES.find((page: any) => page.PAGE_TYPE === "OVERVIEW")}
          onSvgConverter={svgConverter}
        />
      )}
      <CaloricBalancePage
        data={data.PAGES.find(
          (page: any) => page.PAGE_TYPE === "CALORIC_BALANCE"
        )}
        onSvgConverter={svgConverter}
        isRestoreReport={data.IS_RESTORE}
      />
      <MacronutrientBalancePage
        data={data.PAGES.find(
          (page: any) => page.PAGE_TYPE === "MACRO_BALANCE"
        )}
        onSvgConverter={svgConverter}
      />
      <TestingSchedulePage
        data={data.PAGES.find(
          (page: any) => page.PAGE_TYPE === "TESTING_SCHEDULE"
        )}
        onSvgConverter={svgConverter}
        isRestoreReport={data.IS_RESTORE}
      />
      {data.USERSERVICES === 0 &&
        data.PAGES.find((page: any) => page.PAGE_TYPE === "SERVICES") && (
          <AuxiliaryTreatmentsPageV02
            data={data.PAGES.find((page: any) => page.PAGE_TYPE === "SERVICES")}
          />
        )}
    </>
  );
};

export default ReportRMR;
