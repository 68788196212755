import axios, { AxiosInstance } from "axios";

const BASE_URL_PLATFORM_HEADER = "dev";

const apiManager = async (
  platform: string,
  envHeader: string,
  authorization: boolean,
  navigate?: (path: string) => void,
  id?: string,
  type?: string,
  lang?: string
) => {
  const token = localStorage.getItem("token");

  // if (authorization && !token) {
  //   console.log("no token");
  //   return null;
  // }
  let baseUrl;

  if (platform === "nut-user") {
    envHeader === "prod"
      ? (baseUrl = "https://nut.mypnoe.com")
      : (baseUrl = "https://nut-dev.mypnoe.com");
  } else if (platform === "platform-user") {
    envHeader === "prod"
      ? (baseUrl = "https://pnoeapi.mypnoe.com")
      : (baseUrl = "https://pnoeapi-dev.mypnoe.com");
  }

  // console.log(baseUrl, envHeader, authorization);

  const instance = axios.create({
    baseURL: baseUrl,
    headers: {
      "Content-Type": "application/json",
      "X-Environment": envHeader,
      ...(authorization && token && { Authorization: `${token}` }),
    },
  });

  instance.interceptors.request.use(
    (x) => {
      const headers = {
        ...x.headers.common,
        ...x.headers[x.method],
        ...x.headers,
      };
      // remove unrelevant headers to reduce the noise
      ["common", "get", "post", "head", "put", "patch", "delete"].forEach(
        (header) => {
          delete headers[header];
        }
      );

      return x;
    },
    function (error) {
      console.log("initial error", error);
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      // If the error status is 401 or 404 and there is no originalRequest._retry flag,
      // it means the token has expired and we need to refresh it
      // console.log("error initial request", error);
      if (
        (error.response.status === 401 ||
          error.response.status === 404 ||
          error.response.status === 406) &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;

        try {
          const refreshToken = `${localStorage.getItem("refreshToken")}`;
          const id = `${localStorage.getItem("cognitoId")}`;
          const response = await axios.post(
            `https://id${
              envHeader === "dev" ? "-dev" : ""
            }.mypnoe.com/api/users/refresh-token`,
            {
              id: id,
              refresh_token: refreshToken,
            }
          );
          const { id_token } = response.data;

          localStorage.setItem("token", `Bearer ${id_token}`);

          // Retry the original request with the new token
          originalRequest.headers.Authorization = `Bearer ${id_token}`;
          return axios(originalRequest);
        } catch (error) {
          console.log("refresh token error", error);
          if (navigate) {
            if (
              id !== "undefined" &&
              type !== "undefined" &&
              lang !== "undefined"
            ) {
              const paramReport = JSON.stringify({
                idReport: id,
                type: type,
                lang: lang,
              });
              localStorage.setItem("paramReport", paramReport);
            }
            navigate(`/auth?mode=login`);
          }
          // window.location.href = "/auth?mode=login";
        }
      }
      // Throw the error for status code 422
      if (error.response.status === 422) {
        return error;
      }
      return Promise.reject(error);
    }
  );
  return instance;
};

export const LoginUser = async (data: any) => {
  const client: AxiosInstance | null = await apiManager(
    "platform-user",
    BASE_URL_PLATFORM_HEADER,
    false
  );
  if (!client) {
    return null;
  }

  const response = await client.post("/api/v2/Login", data);
  const token = response.data.token;
  const refreshToken = response.data.refresh_token;
  const cognitoId = response.data.identity;

  if (token && refreshToken) {
    localStorage.setItem("token", `Bearer ${token}`);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("cognitoId", cognitoId);
  }

  const user = await GetUser();

  return user;
};

export const GetUser = async () => {
  const client: AxiosInstance | null = await apiManager(
    "platform-user",
    BASE_URL_PLATFORM_HEADER,
    true
  );
  if (!client) {
    return null;
  }

  const response = await client.get("/api/v2/Login/GetUser");
  // console.log("API", response.data);
  return response.data;
};

export const QuestionnaireCompleted = async (
  navigate: (path: string) => void,
  authType: boolean
) => {
  const client: AxiosInstance | null = await apiManager(
    "nut-user",
    BASE_URL_PLATFORM_HEADER,
    authType,
    navigate
  );

  if (!client) {
    return null;
  }

  const response = await client.get("/api/customer/questionnaire/completed");

  return response;
};

export const getCustomerEmail = async (id: string) => {
  const client: AxiosInstance | null = await apiManager(
    "platform-user",
    BASE_URL_PLATFORM_HEADER,
    false
  );
  if (!client) {
    return null;
  }

  const response = await client.get(
    `/api/v1/Registration/GetRegistration/${id}`
  );

  return response.data;
};

export const ActivateUser = async (data) => {
  const client: AxiosInstance | null = await apiManager(
    "platform-user",
    BASE_URL_PLATFORM_HEADER,
    false
  );
  if (!client) {
    return null;
  }

  const response = await client.post("/api/v1/Registration/Activate", data);
  const token = response.data.token;
  const refreshToken = response.data.refresh_token;
  const cognitoId = response.data.identity;

  if (token) {
    localStorage.setItem("token", `Bearer ${token}`);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("cognitoId", cognitoId);
  }

  const user = await GetUser();

  return user;
};

export const SendPasswordReset = async (email: { email: string }) => {
  const client: AxiosInstance | null = await apiManager(
    "platform-user",
    BASE_URL_PLATFORM_HEADER,
    false
  );
  if (!client) {
    return null;
  }
  await client.get(`/api/v1/User/SendPasswordReset/${email}`);
};

export const ResetPassword = async (data: {
  password: string;
  passwordConfirm?: string;
  token?: string | null;
}) => {
  const client: AxiosInstance | null = await apiManager(
    "platform-user",
    BASE_URL_PLATFORM_HEADER,
    false
  );
  if (!client) {
    return null;
  }

  await client.post("/api/v1/User/ResetPassword", data);
};

export const submitBiometricQuestionnaire = async (data: any) => {
  const client: AxiosInstance | null = await apiManager(
    "nut-user",
    BASE_URL_PLATFORM_HEADER,
    true
  );
  if (!client) {
    return null;
  }

  const response = await client.post(
    "/api/customer/questionnaire/biometrics",
    data
  );

  return response;
};

export const submitLifestyleQuestionnaire = async (data: any) => {
  const client: AxiosInstance | null = await apiManager(
    "nut-user",
    BASE_URL_PLATFORM_HEADER,
    true
  );
  if (!client) {
    return null;
  }

  const response = await client.post(
    "/api/customer/questionnaire/lifestyle",
    data
  );

  return response;
};

export const submitBiometricQuestionnaireToPlatform = async (data: any) => {
  const client: AxiosInstance | null = await apiManager(
    "platform-user",
    BASE_URL_PLATFORM_HEADER,
    false
  );
  if (!client) {
    return null;
  }

  const response = await client.post(
    "/api/v1/Registration/UpdateProfile",
    data
  );

  return response;
};

export const responseFail = async (data: any) => {
  const client: AxiosInstance | null = await apiManager(
    "platform-user",
    BASE_URL_PLATFORM_HEADER,
    false
  );
  if (!client) {
    return null;
  }

  const response = await client.post("/api/v1/Registration/PostFail", data);

  return response;
};

export const getReportData = async (
  id: string,
  type: string,
  lang: string,
  setData: any,
  setIsLoading: Function,
  setError: Function,
  navigate: (path: string) => void,
  isPlatformApp: string
) => {
  const client: AxiosInstance | null = await apiManager(
    "platform-user",
    BASE_URL_PLATFORM_HEADER,
    isPlatformApp !== "1",
    navigate,
    id,
    type,
    lang
  );
  if (!client) {
    return null;
  }

  const response = await client.get(
    `/api/v2/Report/${
      isPlatformApp === "1" ? "GetReportJsonP" : "GetReportJson"
    }/${id}/${type}/${lang}`
  );

  if (response.status === 200) {
    const dataAPI = await response.data;
    setData(dataAPI);
    setIsLoading(false);
    localStorage.removeItem("paramReport");
  } else {
    console.log("error Reports", response);
    setError(response?.message);
    setIsLoading(false);
  }
};
