import Button from "../../../common/ui/Button";
import { useNavigate } from "react-router-dom";

import classes from "./IntroPage.module.css";
import logo from "../../../common/images/logo.png";
import { useEffect } from "react";
import backgroundImg from "../../../common/images/backgroundImg.png";

const IntroPage = () => {
  const navigate = useNavigate();
  const handleStart = () => {
    navigate("/biometric-questionnaire");
  };

  useEffect(() => {
    const infoJSON = localStorage.getItem("Info");
    const info = JSON.parse(infoJSON);
    if (info === null || info.pnoe_user_id === "") {
      navigate("/auth");
    }
  }, []);

  return (
    <div
      className={classes.container}
      style={{
        backgroundImage: `url('${backgroundImg}')`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "var(--app-height)",
        color: "var(--secondary001)",
      }}
    >
      <div className={classes.containerOverlay}>
        <div className={classes.inner}>
          <div className={classes.imgContainer}>
            <img className={classes.logo} src={logo} />
          </div>
          <div className={classes.title}>
            Welcome to our biometric questionnaire!
          </div>
          <div className={classes.subTitle}>
            Our experts have put together a few questions to provide the optimal
            training and nutrition recommendations that work for your lifestyle.
          </div>
          <div className={classes.btn}>
            <Button text="Start" customWidth="150px" onClick={handleStart} />
            <div className={classes.subtitle2}>Will only take 6 min</div>
          </div>
        </div>

        <div className={classes.btnMobile}>
          <Button text="Start" customWidth="100%" onClick={handleStart} />
          <div className={classes.subtitle2}>Will only take 6 min</div>
        </div>
      </div>
    </div>
  );
};

export default IntroPage;
