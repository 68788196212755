import { MouseEventHandler, useState } from "react";

import classes from "./Button.module.css";

type Props = {
  text: string;
  onClick?: MouseEventHandler;
  isDisabled?: boolean;
  customWidth?: string;
  customMargin?: string;
  customHeight?: string;
  type?: any;
};
const OutlineButton = ({
  text,
  onClick,
  isDisabled,
  customWidth,
  customMargin,
  customHeight,
  type,
}: Props) => {
  return (
    <button
      type={type}
      className={`${classes.btn} ${classes.btnOutline}`}
      style={{ width: customWidth, margin: customMargin, height: customHeight }}
      onClick={onClick}
      disabled={isDisabled}
    >
      <span>{text}</span>
    </button>
  );
};

export default OutlineButton;
