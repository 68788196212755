import { MouseEventHandler, useState } from "react";

import classes from "./Button.module.css";

type Props = {
  text: string;
  onClick?: MouseEventHandler;
  isDisabled?: boolean;
  customWidth?: string;
  customMargin?: string;
  customHeight?: string;
  type?: any;
};

const Button = ({
  text,
  onClick,
  isDisabled,
  customWidth,
  customMargin,
  customHeight,
  type,
}: Props) => {
  const [showHover, setShowHover] = useState(false);

  const handleMouseEnter = () => {
    setShowHover(true);
  };
  const handleMouseLeave = () => {
    setShowHover(false);
  };
  return (
    <button
      type={type}
      className={classes.btn}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={
        isDisabled
          ? {
              opacity: 0.2,
              cursor: "initial",
              width: customWidth,
              height: customHeight,
            }
          : showHover
          ? {
              backgroundColor: "var(--secondary03-hover)",
              width: customWidth,
              margin: customMargin,
              height: customHeight,
            }
          : { width: customWidth, margin: customMargin, height: customHeight }
      }
      onClick={onClick}
      disabled={isDisabled}
    >
      <span>{text}</span>
    </button>
  );
};

export default Button;
