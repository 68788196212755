import OverviewMetricBarGraphPage from "../pages/OverviewMetricBarGraphPage/OverviewMetricBarGraphPage";
import CustomCoverPage from "../pages/CustomCoverPage/CustomCoverPage";
import TrainerNotePage from "../pages/TrainerNotePage/TrainerNotePage";

type Props = {
  data: any;
  svgConverter: (
    content: string,
    result: string,
    imgWidth: number
  ) => JSX.Element;
  reportType: string;
};

const CustomReportAMR = ({ data, svgConverter, reportType }: Props) => {
  return (
    <>
      <CustomCoverPage />
      <TrainerNotePage data={data.NOTES} />
      <OverviewMetricBarGraphPage
        data={data.PAGES.find((page: any) => page.PAGE_TYPE === "OVERVIEW")}
        onSvgConverter={svgConverter}
        isCustomReport={true}
        reportType={
          reportType === "10" || reportType === "12" || reportType === "14"
            ? "isAMR"
            : "isRMR"
        }
      />
    </>
  );
};

export default CustomReportAMR;
