import classes from "./CardDescription.module.css";

import Arrow0 from "../../images/Arrow_0.png";
import Arrow1 from "../../images/Arrow_1.png";
import Arrow2 from "../../images/Arrow_2.png";
import Arrow3 from "../../images/Arrow_3.png";
import ColdWebRestore from "../../images/restore/ColdWebRestore.jpg";
import ivDripRestore from "../../images/restore/ivDripRestore.png";
import syringeRestore from "../../images/restore/syringeRestore.png";
import saunaRestore from "../../images/restore/saunaRestore.png";
import compressionRestore from "../../images/restore/compressionRestore.png";
import o2Restore from "../../images/restore/o2Restore.jpg";
interface Props {
  title: string;
  subtitle?: string;
  description: string;
  index: number;
  useLargerFont?: boolean;
  usecustomStyle?: boolean;
  customWidth?: string;
  customMargin?: number;
  showIcon?: boolean;
  valueIcon?: number;
  showDescriptionInList?: boolean;
  color?: string;
}

const CardDescriptionRestore = ({
  title,
  subtitle,
  description,
  index,
  useLargerFont,
  usecustomStyle,
  customWidth,
  customMargin,
  showIcon,
  valueIcon,
  showDescriptionInList,
  color,
}: Props): JSX.Element => {
  let iconSource: string | undefined;
  let listItems: string[] = [];

  const iconSourceHandler = (valueIcon: number | undefined) => {
    if (showIcon) {
      switch (valueIcon) {
        case 1:
          iconSource = Arrow1;
          break;
        case 2:
          iconSource = Arrow2;
          break;
        case 3:
          iconSource = Arrow3;
          break;
        default:
          iconSource = Arrow0;
      }
    }
    if (
      title === "L-Carnitine" ||
      title === "B12" ||
      title === "B-Complex (B1,B2,B3,B5,B6)"
    ) {
      iconSource = syringeRestore;
    }
    return iconSource;
  };

  const iconRightHandler = (title: string) => {
    switch (title) {
      case "Cryotherapy":
        iconSource = ColdWebRestore;
        break;
      case "Glutamine":
      case "Taurine":
      case "L-Carnitine":
      case "NAC (N-Acetyl-Cysteine)":
      case "B-Complex (B1,B2,B3,B5,B6)":
      case "B12":
      case "Proline":
      case "Magnesium sulphate":
      case "Magnesium":
        iconSource = ivDripRestore;
        break;
      case "Lipo":
      case "Tri-Immune (Glutathione + Zinc + Vitamin C)":
      case "CoQ10":
      case "Amino Blend (Arg, Cit, Lys, Pro)":
      case "Vitamin D":
        iconSource = syringeRestore;
        break;
      case "Infrared sauna":
        iconSource = saunaRestore;
        break;
      case "Compression":
        iconSource = compressionRestore;
        break;
      case "Mild Hyperbaric Oxygen therapy":
        iconSource = o2Restore;
        break;
        break;
      default:
        iconSource = Arrow0;
    }

    return iconSource;
  };

  const extractListItems = (str: string) => {
    // Use a regular expression to match the content inside <li> tags
    const regex = /<li>(.*?)<\/li>/g;
    let match;
    const items = [];

    // Loop through all matches
    while ((match = regex.exec(str)) !== null) {
      items.push(match[1]);
    }

    return items;
  };
  if (showDescriptionInList) {
    listItems = extractListItems(description);
  }

  return (
    <div
      className={classes.sub}
      style={{ width: customWidth, margin: customMargin }}
    >
      <div className={classes.inner}>
        <div className={classes.iconRestore}>
          <img src={iconRightHandler(title)} alt="icon" />
        </div>
        <div
          className={classes.titleRestore}
          style={{
            maxWidth: title === "B-Complex (B1,B2,B3,B5,B6)" ? "61%" : "80%",
          }}
        >
          {title}
        </div>
        {(showIcon ||
          title === "L-Carnitine" ||
          title === "B12" ||
          title === "B-Complex (B1,B2,B3,B5,B6)") && (
          <div
            className={classes.icon}
            style={
              title === "L-Carnitine" ||
              title === "B12" ||
              title === "B-Complex (B1,B2,B3,B5,B6)"
                ? { width: "32px", height: "32px" }
                : {}
            }
          >
            <img src={iconSourceHandler(valueIcon)} alt="icon" />
          </div>
        )}
      </div>
      <div className={classes.subtitle} style={{ paddingLeft: "24px" }}>
        May help the body
      </div>
      <div
        className={classes.subText}
        style={{ paddingLeft: "24px", width: "95%" }}
      >
        {showDescriptionInList ? (
          <div>
            {listItems.map((item, index) => (
              <div key={index} className={classes.listItem}>
                <div
                  className={classes.point}
                  style={{ backgroundColor: color || "var(--primary1000)" }}
                ></div>
                <div
                  className={classes.subText}
                  style={{ marginBottom: 0, flex: 1 }}
                >
                  {item}
                </div>
              </div>
            ))}
          </div>
        ) : (
          description
        )}
      </div>
    </div>
  );
};

export default CardDescriptionRestore;
