import classes from "./Factor.module.css";

import arrowDown from "../../images/arrowDown.png";
import arrowUp from "../../images/arrowUp.png";

interface Props {
  factor: string;
  valueArrow: number;
}

const Factor = ({ factor, valueArrow }: Props): JSX.Element => {
  return (
    <div className={classes.factors}>
      <div className={classes.factor}>{factor}</div>
      {/*// When value is -1 we show the arrow up icon*/}
      {/*// When value is 1 we show the arrow down icon*/}
      {/*// When value is 0 we don't show any icon*/}
      {valueArrow === 0 && <p>-</p>}
      {valueArrow === 1 && (
        <img
          className={classes.arrow}
          src={arrowDown}
          alt="arrow shows how the factor affects the biological age"
        />
      )}
      {valueArrow === -1 && (
        <img
          className={classes.arrow}
          src={arrowUp}
          alt="arrow shows how the factor affects the biological age"
        />
      )}
    </div>
  );
};

export default Factor;
