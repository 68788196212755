import React, { MouseEventHandler, useState } from "react";

import Eye_open from "../../../common/svgs/Eye_open";
import Eye_close from "../../../common/svgs/Eye_close";
import { useField } from "formik";

import classes from "./TextInput.module.css";

type Props = {
  label: string;
  showPwd?: boolean;
  onClick?: any;
  setCurPassword?: Function;
  // setIsFocused?: any;
  hasValidator?: boolean;
  name: string;
  type?: string;
};
const TextInput = ({
  label,
  showPwd,
  onClick,
  setCurPassword,
  // setIsFocused,
  hasValidator,
  ...props
}: Props) => {
  const [field, meta] = useField(props);

  if (hasValidator) {
    // @ts-ignore
    setCurPassword(field.value);
  }

  // const handleFocus = () => {
  //   if (hasValidator) {
  //     setIsFocused(true);
  //   }
  // };
  //
  // const handleBlur = () => {
  //   if (hasValidator) {
  //     setIsFocused(false);
  //   }
  // };

  return (
    <div className={classes.container}>
      <input
        className={classes.input}
        style={meta.error ? { border: "1px solid var(--error00)" } : {}}
        {...field}
        {...props}
        required
        // onFocus={handleFocus}
        // onBlur={handleBlur}
      />
      <span className={classes.floatingLabel}>{label}</span>
      <div className={classes.error}>{meta.error}</div>
      {(props.name === "password" || props.name === "passwordConfirm") && (
        <div>
          {!showPwd && <Eye_open onClick={onClick} />}

          {showPwd && <Eye_close onClick={onClick} />}
        </div>
      )}
    </div>
  );
};

export default TextInput;
