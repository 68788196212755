import classes from "./Layout.module.css";
import sideAuthImg from "../../../common/images/sideAuthImg.png";

type Props = {
  children: any;
};
const Layout = ({ children }: Props) => {
  return (
    <div className={classes.container}>
      <div
        className={classes.side}
        style={{
          backgroundImage: `url('${sideAuthImg}')`,
          backgroundPosition: "top center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          color: "var(--secondary001)",
        }}
      />
      <div className={classes.main}>
        <div className={classes.header}>
          <img
            className={classes.logo}
            src={require("../../../common/images/p_logo.png")}
            alt="main"
          />
        </div>
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
};
export default Layout;
