import { useEffect, useState } from "react";

import Layout from "../../components/reusable/Layout";
import Title from "../../components/reusable/Title";
import CustomCard from "../../components/reusable/CustomCard";

import Navbar from "../../components/navbar/Navbar";
import MetricsSection from "./MetricsSection";
import RiskChartBar from "../../components/reusable/RiskChartBar";

import classes from "./OverviewMetricBarGraphPage.module.css";
import CalsTable from "../CaloricBalance/CalsTable";
import FuelSources from "../CaloricBalance/FuelSources";

import arrowBlueRight from "../../images/arrowBlueRight.png";
import overviewOrganSystem from "../../images/overviewOrganSystem.png";
import Xarrow, { Xwrapper } from "react-xarrows";

type Tab = { INDEX: number; NAME: string; VALUE: number };

type improveArea = { INDEX: number; TEXT: string; TITLE: string };

type Metric = {
  HOW_IT_IS_MEASURED: string;
  HOW_IT_IS_MEASURED_TEXT: string;
  INDEX: number;
  SUGGESTIONS: { IMPROVE_AREAS: improveArea[]; INDEX: number; TITLE: string }[];
  TITLE1: string;
  TITLE2: string;
  SUBTITLE?: string;
  TOP: string;
  VALUE: number;
  WHAT_IT_IS: string;
  WHAT_IT_IS_TEXT: string;
  WHY_ITS_IMPORTANT: string;
  WHY_ITS_IMPORTANT_TEXT: string;
  NORISK?: string;
  HIGHRISK?: string;
  METRIC_TYPE: number;
};

interface Props {
  data: {
    DESCRIPTION: null;
    INDEX: number;
    ORGANS: any;
    METRICS: Metric[];
    NAVBAR: Tab[];
    RISK_METRICS: Metric[];
    PERFORMANCE_METRICS: Metric[] | null;
    QRCODE: {
      FOOTER1: string;
      HEADER1: string;
      HEADER2: string;
      QRCODE: string;
    };

    TITLE: string;

    TITLE_PERFORMANCE_METRICS: string;
  };
  data2?: any;
  isCustomReport?: boolean;
  onSvgConverter: (
    content: string,
    result: string,
    imgWidth: number
  ) => JSX.Element;
  reportType: string;
  isCreatingPDF?: boolean;
}

const OverviewMetricBarGraphPage = ({
  data,
  data2,
  isCustomReport,
  onSvgConverter,
  reportType,
  isCreatingPDF,
}: Props): JSX.Element => {
  const [imgRiskBar, setImgRiskBar] = useState(false);

  useEffect(() => {
    if (data.ORGANS.CELLS.RISK_METRICS.length > 0 && !isCustomReport) {
      onSvgConverter("riskBar", "chartBarsHeader-8", 530);
      setImgRiskBar(true);
    }
  }, [onSvgConverter]);

  // Flatten METRICS from ORGANS
  const flattenedArray = Object.values(data.ORGANS).flatMap((organ: any) => [
    ...organ.METRICS,
  ]);

  // create the connection between the metrics and the organ system
  let filteredMetrics: Metric[] = [];
  if (reportType === "isAMR") {
    filteredMetrics = flattenedArray.filter(
      (metric) =>
        metric.METRIC_TYPE === 18 ||
        metric.METRIC_TYPE === 19 ||
        metric.METRIC_TYPE === 24
    );
  } else {
    filteredMetrics = [
      ...flattenedArray,
      ...data.ORGANS.CELLS.RISK_METRICS,
    ].filter(
      (metric) =>
        // TODO: Need to find out what these METRIC_TYPE values are for the first two conditions
        metric.METRIC_TYPE === 8 ||
        metric.METRIC_TYPE === 19 ||
        metric.METRIC_TYPE === 24 ||
        metric.METRIC_TYPE === 25
    );
  }

  // Map the filtered array to the desired format
  const activeConnections = filteredMetrics.map((metric) => ({
    id: metric.METRIC_TYPE,
    start: `chartBarsHeader-${metric.METRIC_TYPE}`,
    end: `chartBarsHeader-${metric.METRIC_TYPE}-end`,
  }));

  const RiskBar = (
    <div id="riskBar" style={{ marginTop: "5px" }}>
      <RiskChartBar
        data={data.ORGANS.CELLS.RISK_METRICS[0]}
        onSvgConverter={onSvgConverter}
        useLargerFont={false}
      />
    </div>
  );

  return (
    <Layout>
      <Title title={data.TITLE} />
      <Navbar data={data.NAVBAR} />
      {!isCustomReport && (
        <Xwrapper>
          <div className={classes.container}>
            <div
              className={classes.organSystemContainer}
              style={{
                borderColor:
                  reportType === "isAMR" ? "var(--secondary26)" : "transparent",
              }}
            >
              <img
                src={overviewOrganSystem}
                style={{ width: "100%", objectFit: "contain" }}
                alt="Organ system"
              />
              {reportType === "isAMR" && (
                <img
                  src={arrowBlueRight}
                  style={{ width: "27px" }}
                  alt="connection to Organ system"
                  className={classes.organSystemContainerArrow}
                />
              )}
              <div
                className={classes.organSystemContainerLabel}
                style={{ top: "20px" }}
              >
                {data.ORGANS.BRAIN.LABELS[0]?.VALUE}
              </div>
              <div
                className={classes.organSystemContainerLabel}
                style={{ top: "235px" }}
              >
                {data.ORGANS.LUNGS.LABELS[0]?.VALUE}
              </div>
              <div
                className={classes.organSystemContainerLabel}
                style={{ top: "360px" }}
              >
                {data.ORGANS.LUNGS.LABELS[1]?.VALUE}
              </div>
              <div
                className={classes.organSystemContainerLabel}
                style={{ top: "396px" }}
              >
                {data.ORGANS.LUNGS.LABELS[2]?.VALUE}
              </div>
              <div
                className={classes.organSystemContainerLabel}
                style={{ top: "558px" }}
              >
                {data.ORGANS.HEART.LABELS[0]?.VALUE}
              </div>
              <div
                className={classes.organSystemContainerLabel}
                style={{ top: "733px" }}
              >
                {data.ORGANS.CELLS.LABELS[0]?.VALUE}
              </div>
              {/*//Dot for the line connection with metrics*/}
              {!isCreatingPDF &&
                activeConnections.find(
                  (connection) => connection.id === 24
                ) && (
                  <div
                    id="chartBarsHeader-24-end"
                    className={classes.organSystemContainerDot}
                    style={{ top: "130px", left: "133px" }}
                  />
                )}
              {!isCreatingPDF &&
                activeConnections.find(
                  (connection) => connection.id === 25
                ) && (
                  <div
                    id="chartBarsHeader-25-end"
                    className={classes.organSystemContainerDot}
                    style={{ top: "135px", left: "65px" }}
                  />
                )}
              {!isCreatingPDF &&
                activeConnections.find(
                  (connection) => connection.id === 19
                ) && (
                  <div
                    id="chartBarsHeader-19-end"
                    className={classes.organSystemContainerDot}
                    style={{ top: "400px", left: "118px" }}
                  />
                )}
              {!isCreatingPDF &&
                activeConnections.find(
                  (connection) => connection.id === 18
                ) && (
                  <div
                    id="chartBarsHeader-18-end"
                    className={classes.organSystemContainerDot}
                    style={{
                      top: "796px",
                      left: "147px",
                      backgroundColor: "transparent",
                    }}
                  />
                )}
              {!isCreatingPDF &&
                activeConnections.find((connection) => connection.id === 8) && (
                  <div
                    id="chartBarsHeader-8-end"
                    className={classes.organSystemContainerDot}
                    style={{
                      top: "796px",
                      left: "147px",
                      backgroundColor: "transparent",
                    }}
                  />
                )}
            </div>
            <div className={classes.metricContainer}>
              {data.ORGANS.AMR.METRICS.length > 0 && (
                <div
                  className={classes.sectionContainer}
                  style={{ backgroundColor: "transparent", height: "80px" }}
                >
                  <MetricsSection
                    data={data.ORGANS.AMR.METRICS}
                    labels={data.NAVBAR.slice(0, -1)}
                  />
                </div>
              )}
              <div
                className={classes.sectionContainer}
                style={{ height: reportType === "isAMR" ? "80px" : "208px" }}
              >
                <MetricsSection
                  data={data.ORGANS.BRAIN.METRICS}
                  labels={data.NAVBAR.slice(0, -1)}
                />
              </div>
              <div
                className={classes.sectionContainer}
                style={{ height: reportType === "isAMR" ? "348px" : "281px" }}
              >
                <MetricsSection
                  data={data.ORGANS.LUNGS.METRICS}
                  labels={data.NAVBAR.slice(0, -1)}
                />
              </div>
              <div
                className={classes.sectionContainer}
                style={{ height: reportType === "isAMR" ? "80px" : "120px" }}
              >
                <MetricsSection
                  data={data.ORGANS.HEART.METRICS}
                  labels={data.NAVBAR.slice(0, -1)}
                />
              </div>
              <div
                className={classes.sectionContainer}
                style={{
                  height: reportType === "isAMR" ? "355px" : "300px",
                }}
              >
                <MetricsSection
                  data={data.ORGANS.CELLS.METRICS}
                  labels={data.NAVBAR.slice(0, -1)}
                />
                {data.ORGANS.CELLS.RISK_METRICS.length > 0 && (
                  <div id="chartBarsHeader-8">{!imgRiskBar && RiskBar}</div>
                )}
              </div>
            </div>
          </div>
          {!isCreatingPDF &&
            activeConnections.map((connection, index) => (
              <Xarrow
                key={index}
                curveness={0}
                start={connection.start}
                end={connection.end}
                endAnchor={"middle"}
                showHead={false}
                strokeWidth={1}
                color="var(--primary400)"
              />
            ))}
        </Xwrapper>
      )}
      {isCustomReport && (
        <div>
          <MetricsSection
            data={flattenedArray}
            labels={data.NAVBAR.slice(0, -1)}
          />
          {reportType === "isRMR" && (
            <div style={{ marginTop: "40px" }}>
              <CalsTable data={data2.SECTION2} />
              <FuelSources data={data2.SECTION3} />
            </div>
          )}
        </div>
      )}
    </Layout>
  );
};

export default OverviewMetricBarGraphPage;
