import { useEffect, useState } from "react";
import classes from "./Scale.module.css";

const Scale = ({ id, dispatchAnswer, label, answer, min, max }) => {
  const [num, setNum] = useState(+answer);
  const [upStyle, setUpStyle] = useState("inactive");
  const [downStyle, setDownStyle] = useState("inactive");
  const [focusInput, setFocusInput] = useState(false);

  useEffect(() => {
    dispatchAnswer(num, id);
  }, [num, id]);

  const increment = () => {
    if (answer >= max) {
      setUpStyle("disable");
      return;
    } else {
      setUpStyle("active");
    }
    setFocusInput(true);
    setUpStyle("hover");
    setDownStyle("active");
    setTimeout(() => {
      setUpStyle("active");
      setFocusInput(false);
    }, 100);

    setNum(num < max ? num + 1 : +num);
  };

  const decrement = () => {
    if (answer <= min) {
      setDownStyle("disable");
      return;
    } else {
      setDownStyle("active");
    }
    setFocusInput(true);
    setDownStyle("hover");
    setUpStyle("active");
    setTimeout(() => {
      setDownStyle("active");
      setFocusInput(false);
    }, 100);

    setNum(num >= min ? num - 1 : num);
  };

  const handleChangeInput = (e) => {
    e.preventDefault();

    let inputValue = +e.target.value;

    if (inputValue <= min) {
      setDownStyle("inactive");
      setUpStyle("active");
      setNum(min);
    } else if (inputValue >= max) {
      setDownStyle("active");
      setUpStyle("inactive");
      setNum(max);
    } else {
      setDownStyle("active");
      setUpStyle("active");
      setNum(inputValue);
    }
  };
  const getArrowStyle = (arrow) => {
    switch (arrow) {
      case "inactive":
        return "var(--primary02)";

      case "active":
        return "var(--secondary03-active)";

      case "hover":
        return "var(--secondary03-hover)";

      case "disable":
        return "var(--primary04)";
    }
  };

  return (
    <div className={classes.container}>
      <svg
        width="16"
        height="9"
        viewBox="0 0 16 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classes.icon}
        onClick={increment}
      >
        <path d="M8 0L0 9H16L8 0Z" fill={getArrowStyle(upStyle)} />
      </svg>

      <label className={classes.text}>
        <input
          type="number"
          value={answer ? answer : 0}
          onChange={handleChangeInput}
          className={`${classes.input} ${answer !== 0 && classes.inputOk}`}
          style={focusInput ? { border: "1px solid var(--secondary02)" } : {}}
        />
        {label}
      </label>
      <svg
        width="16"
        height="9"
        viewBox="0 0 16 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classes.icon}
        onClick={decrement}
        style={{ transform: "matrix(1, 0, 0, -1, 0, 0)" }}
      >
        <path d="M8 0L0 9H16L8 0Z" fill={getArrowStyle(downStyle)} />
      </svg>
    </div>
  );
};

export default Scale;
