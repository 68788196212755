import okIcon from "../../../common/images/okIcon.png";
import appstore from "../../../common/images/appstore.png";
import googleplay from "../../../common/images/googleplay.png";

import backgroundImg from "../../../common/images/backgroundImg.png";

import classes from "./SuccessPage.module.css";
import { useEffect, useState } from "react";
import { QuestionnaireCompleted } from "../../../api";
import { answersActions } from "../../store/answers-slice";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../../common/ui/PageLoader";
import Button from "../../../common/ui/Button";
import { useDispatch, useSelector } from "react-redux";
import { stepperActions } from "../../store/stepper-slice";

const SuccessPageBiometric = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasLifestyleQuestionnaireAccess = useSelector(
    (state) => state.stepper.hasLifestyleQuestionnaireAccess
  );
  const isLifestyleQuestionnaireCompleted = useSelector(
    (state) => state.stepper.isLifestyleCompleted
  );

  console.log(
    "needed",
    hasLifestyleQuestionnaireAccess,
    "completed",
    isLifestyleQuestionnaireCompleted
  );

  const handleIfLifestyleQuestionnaireCompleted = () => {
    if (isLifestyleQuestionnaireCompleted) {
      navigate("/success-lifestyle");
    } else {
      if (!hasLifestyleQuestionnaireAccess) {
        // const notes = JSON.parse(localStorage.getItem("notes"));
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("notes");
        localStorage.removeItem("Info");
      }
      dispatch(stepperActions.goToStep({ step: 1 }));
      console.log("Lifestyle questionnaire is not completed");
    }
  };

  useEffect(() => {
    handleIfLifestyleQuestionnaireCompleted();
  }, []);

  return (
    <div
      className={classes.container}
      style={{
        backgroundImage: `url('${backgroundImg}')`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "var(--app-height)",
        color: "var(--secondary001)",
      }}
    >
      <div className={classes.containerOverlay}>
        {isLoading && <PageLoader />}
        {!isLoading && (
          <>
            <div className={classes.inner}>
              <div className={classes.imgContainer}>
                <img className={classes.logo} src={okIcon} />
              </div>

              <div className={classes.title}>
                You have successfully completed our biometrics questionnaire!
              </div>

              {hasLifestyleQuestionnaireAccess ? (
                <div>
                  <div className={classes.subTitle}>
                    Please proceed with the lifestyle questionnaire
                  </div>
                  <div className={classes.btn}>
                    <Button
                      text="Continue"
                      customWidth="150px"
                      onClick={() => navigate("/lifestyle-questionnaire")}
                    />
                    <div className={classes.subtitle2}>
                      Will only take 10 min
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className={classes.subTitle}>
                    Thank you for having all of our questions answered.
                  </div>{" "}
                  <div className={classes.bottom}>Download the App</div>
                  <div className={classes.btnIcons}>
                    <a
                      href="https://play.google.com/store/apps/details?id=pnoe.rn.precision"
                      target="_blank"
                    >
                      <img
                        className={classes.img}
                        src={googleplay}
                        alt="icon google play"
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/us/app/pno%C4%93-precision/id6450449936"
                      target="_blank"
                    >
                      <img
                        className={classes.img}
                        src={appstore}
                        alt="icon appstore"
                      />
                    </a>
                  </div>
                </div>
              )}
            </div>
            {hasLifestyleQuestionnaireAccess && (
              <div className={classes.btnMobile}>
                <Button
                  text="Continue"
                  customWidth="100%"
                  onClick={() => navigate("/lifestyle-questionnaire")}
                />
                <div className={classes.subtitle2}>Will only take 10 min</div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SuccessPageBiometric;
