import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import PageLoader from "../../../common/ui/PageLoader";

import classes from "./ErrorPage.module.css";

import backgroundImg from "../../../common/images/backgroundImg.png";
import logo from "../../../common/images/logo.png";

import { submitBiometricQuestionnaire } from "../../../api";
import { stepperActions } from "../../store/stepper-slice";

const ErrorPage = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const answers = useSelector((state) => state.answers);
  const responseStatus = useSelector((state) => state.stepper.responseStatus);

  const handleSubmitBiometricQuestionnaire = async () => {
    try {
      setIsLoading(true);
      const biometricToken = localStorage.getItem("biometricToken");
      let response = await submitBiometricQuestionnaire(
        answers.myAnswers,
        biometricToken
      );
      console.log(response);
      dispatch(stepperActions.handleResponseStatus(response.status));
      localStorage.removeItem("biometricToken");
      localStorage.removeItem("notes");
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      dispatch(stepperActions.handleResponseStatus(e.response.status));

      setIsLoading(false);
    }
  };

  return (
    <div
      className={classes.container}
      style={{
        backgroundImage: `url('${backgroundImg}')`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "100%",
        color: "var(--secondary001)",
      }}
    >
      {isLoading && (
        <div className={classes.containerOverlay}>
          <PageLoader />
        </div>
      )}
      {!isLoading && (
        <div className={classes.containerOverlay}>
          <div className={classes.inner}>
            <div className={classes.imgContainer}>
              <img className={classes.logo} src={logo} />
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 126 126"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={classes.icon}
              >
                <g clipPath="url(#clip0_1355_2479)">
                  <path
                    d="M118.125 0H7.875C3.15 0 0 3.15 0 7.875V118.125C0 122.85 3.15 126 7.875 126H118.125C122.85 126 126 122.85 126 118.125V7.875C126 3.15 122.85 0 118.125 0ZM110.25 110.25H15.75V15.75H110.25V110.25Z"
                    fill="#B7C4D2"
                  />
                  <path
                    d="M70.875 31.5H55.125V70.875H70.875V31.5Z"
                    fill="#B7C4D2"
                  />
                  <path
                    d="M63 94.5C67.3492 94.5 70.875 90.9742 70.875 86.625C70.875 82.2758 67.3492 78.75 63 78.75C58.6508 78.75 55.125 82.2758 55.125 86.625C55.125 90.9742 58.6508 94.5 63 94.5Z"
                    fill="#B7C4D2"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1355_2479">
                    <rect width="126" height="126" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className={classes.title}>{`Error ${responseStatus}`}</div>
            <div
              className={classes.subTitle}
              style={{ width: "100%", marginBottom: "136px" }}
            >
              Sorry, there were some technical issues while processing your
              request.
            </div>
            {/*<div className={classes.btn}>*/}
            {/*  <Button*/}
            {/*    text="Retry"*/}
            {/*    customWidth="287px"*/}
            {/*    onClick={handleSubmitBiometricQuestionnaire}*/}
            {/*  />*/}
            {/*</div>*/}
          </div>
        </div>
      )}
    </div>
  );
};

export default ErrorPage;
