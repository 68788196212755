import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { ActivateUser, QuestionnaireCompleted } from "../../../api";

import TextInput from "../reusable/TextInput";
import CheckBox from "../reusable/CheckBox";
import Button from "../../../common/ui/Button";
import PasswordValidator from "../reusable/PasswordValidator";
import { useNavigate } from "react-router-dom";
import classes from "./SignUpForm.module.css";
import { stepperActions } from "../../../questionnaire/store/stepper-slice";
import { useDispatch } from "react-redux";

type Props = {
  id: string;
  setIsLoading: Function;
  enteredEmail: string;
  setErrorMessage: Function;
  setShowError: Function;
  errorMessage: string;
};

type UserDataProps = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  role: number;
  passwordConfirm: string;
  termsAndConditions: number | boolean;
  userConsent: number | boolean;
};
const SignUpForm = ({
  id,
  setIsLoading,
  enteredEmail,
  setErrorMessage,
  setShowError,
  errorMessage,
}: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [curPassword, setCurPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleShowPassword = () => {
    setShowPassword(() => !showPassword);
  };
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(() => !showConfirmPassword);
  };

  const handleHasLifestyleQuestionnaireAccess = async () => {
    try {
      let response = await QuestionnaireCompleted(navigate, true);
      dispatch(
        stepperActions.handleHasLifestyleQuestionnaireAccess(
          response.data.has_lifestyle_questionnaire_access
        )
      );
    } catch (e) {
      console.log(e);
    }
  };
  const handleActivateUser = async (userData: UserDataProps) => {
    try {
      let response = await ActivateUser(userData);

      // For unknown reason the GetUser return different data structure so I get the info from entity
      const { email, entity, id } = response;

      const info = JSON.stringify({
        email: email,
        firstName: entity.firstName,
        lastName: entity.lastName,
        pnoe_user_id: id,
      });

      localStorage.setItem("Info", info);

      await handleHasLifestyleQuestionnaireAccess();

      setShowError(false);

      navigate("/intro");
    } catch (e) {
      setErrorMessage(e.response.data.error);
      setShowError(true);
    }
  };

  // @ts-ignore
  const handleSubmit = async (values: UserDataProps, { setSubmitting }) => {
    setIsLoading(true);
    const user: UserDataProps = {
      id: id,
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      email: values.email,
      password: values.password,
      role: 1,
      passwordConfirm: values.passwordConfirm,
      termsAndConditions: values.termsAndConditions === true ? 1 : 0,
      userConsent: values.userConsent === true ? 1 : 0,
    };

    await handleActivateUser(user);
    setSubmitting(false);
    setIsLoading(false);
  };

  return (
    <Formik
      initialValues={{
        id: "",
        role: 1,
        firstName: "",
        lastName: "",
        email: enteredEmail,
        password: "",
        passwordConfirm: "",
        termsAndConditions: false, // added from checkbox
        userConsent: false, // added from checkbox
      }}
      validationSchema={Yup.object({
        firstName: Yup.string()
          .max(15, "Must be 15 characters or less")
          .required("This is a required field."),
        lastName: Yup.string()
          .max(20, "Must be 20 characters or less")
          .required("This is a required field."),
        email: Yup.string().email("Invalid email address").required("Required"),
        password: Yup.string()
          .required("This is a required field.")
          .min(8, "Password must be at least 8 characters long.")
          .max(20, "Password must be less than 20 characters long.")
          .matches(
            /(?=.*[a-z])/,
            "Password must contain at least 1 lower case letter."
          )
          .matches(
            /(?=.*[A-Z])/,
            "Password must contain at least 1 upper case letter."
          )
          .matches(
            /(?=.*[!@#$%^&*[\]{}()?"\\,><':;|_~=+-])/,
            "Password must contain at least 1 special character."
          )
          .matches(/(?=.*\d)/, "Password must contain at least 1 number."),
        passwordConfirm: Yup.string()
          .required("Confirm password is required")
          .oneOf([Yup.ref("password"), null], "Password does not match"),
        termsAndConditions: Yup.boolean()
          .required("This is a required field.")
          .oneOf([true], "You must accept the terms and conditions."),
        userConsent: Yup.boolean()
          .required("This is a required field.")
          .oneOf([true], "You must accept the data precessing."),
      })}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      <Form placeholder={""}>
        <div className={classes.inner}>
          <TextInput
            label="First Name"
            name="firstName"
            type="text"
            // placeholder="Enter your First Name here"
          />
          <TextInput
            label="Last Name"
            name="lastName"
            type="text"
            // placeholder="Enter your Last Name here"
          />
          {/*<TextInput*/}
          {/*  label="Email"*/}
          {/*  name="email"*/}
          {/*  type="email"*/}
          {/*  placeholder="Enter your Email here"*/}
          {/*/>*/}
          <div className={classes.inputLocked}>
            <div className={classes.label}>Email</div>
            <div className={classes.input}>
              <div>{enteredEmail}</div>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={classes.icon}
              >
                <path
                  d="M12 6H10V4C10 3.46957 9.78929 2.96086 9.41421 2.58579C9.03914 2.21071 8.53043 2 8 2C7.46957 2 6.96086 2.21071 6.58579 2.58579C6.21071 2.96086 6 3.46957 6 4V6H4V4C4 2.93913 4.42143 1.92172 5.17157 1.17157C5.92172 0.421427 6.93913 0 8 0C9.06087 0 10.0783 0.421427 10.8284 1.17157C11.5786 1.92172 12 2.93913 12 4V6Z"
                  fill="#5A636E"
                />
                <path
                  d="M14 7H2C1.73478 7 1.48043 7.10536 1.29289 7.29289C1.10536 7.48043 1 7.73478 1 8V15C1 15.2652 1.10536 15.5196 1.29289 15.7071C1.48043 15.8946 1.73478 16 2 16H14C14.2652 16 14.5196 15.8946 14.7071 15.7071C14.8946 15.5196 15 15.2652 15 15V8C15 7.73478 14.8946 7.48043 14.7071 7.29289C14.5196 7.10536 14.2652 7 14 7ZM8 13C7.60444 13 7.21776 12.8827 6.88886 12.6629C6.55996 12.4432 6.30362 12.1308 6.15224 11.7654C6.00087 11.3999 5.96126 10.9978 6.03843 10.6098C6.1156 10.2219 6.30608 9.86549 6.58579 9.58579C6.86549 9.30608 7.22186 9.1156 7.60982 9.03843C7.99778 8.96126 8.39991 9.00087 8.76537 9.15224C9.13082 9.30362 9.44318 9.55996 9.66294 9.88886C9.8827 10.2178 10 10.6044 10 11C10 11.5304 9.78929 12.0391 9.41421 12.4142C9.03914 12.7893 8.53043 13 8 13Z"
                  fill="#5A636E"
                />
              </svg>
            </div>
          </div>
          <TextInput
            label="Password"
            name="password"
            type={showPassword ? "text" : "password"}
            onClick={handleShowPassword}
            showPwd={showPassword}
            setCurPassword={setCurPassword}
            hasValidator={true}
          />
          <TextInput
            label="Confirm Password"
            name="passwordConfirm"
            type={showConfirmPassword ? "text" : "password"}
            onClick={handleShowConfirmPassword}
            showPwd={showConfirmPassword}
          />
          <PasswordValidator curPassword={curPassword} />
          <CheckBox name="termsAndConditions" type="terms" />
          <CheckBox name="userConsent" type="dataProcessing" />
        </div>
        <div className={classes.bottom}>
          <Button
            type="submit"
            text="Sign up"
            customWidth="100%"
            customMargin={"53px auto 0"}
            isDisabled={errorMessage.length > 0}
          />
        </div>
      </Form>
    </Formik>
    // </SimpleBar>
  );
};

export default SignUpForm;
