import LoadingSpinner from "./LoadingSpinner";

import classes from "./PageLoader.module.css";

const PageLoader = () => {
  return (
    <div className={classes.loaderContainer}>
      <LoadingSpinner size="massive" />
      <div className={classes.loaderTitle}>Loading</div>
    </div>
  );
};

export default PageLoader;