import SimpleBar from "simplebar-react";

import "simplebar-react/dist/simplebar.min.css";
import classes from "./CustomPicker.module.css";
import "./CustomPicker.css";

import arrowDown from "../../../common/images/arrowDown.png";
import arrowUp from "../../../common/images/arrowUp.png";

const CustomPicker = ({
  type,
  data,
  value,
  setValue,
  showPanel,
  setShowPanel,
  closePanel,
  columns,
  showError,
}) => {
  const onChangeHandler = (e) => {
    setValue(e.target.id);

    closePanel();
  };

  const togglePanel = () => {
    if (type === "Day") {
      setShowPanel("Day");
    } else if (type === "Month") {
      setShowPanel("Month");
    } else if (type === "Year") {
      setShowPanel("Year");
    }

    if (showPanel) {
      closePanel();
    }
  };

  const iconHandler = (type, showPanel) => {
    let iconSrc;
    if (
      (type === "Day" && showPanel === "Day") ||
      (type === "Month" && showPanel === "Month") ||
      (type === "Year" && showPanel === "Year")
    ) {
      iconSrc = arrowDown;
    } else {
      iconSrc = arrowUp;
    }
    return iconSrc;
  };

  return (
    <div
      className={`${classes.container} ${
        type === "Month" && classes.monthContainer
      }`}
    >
      <div className={classes.inputContainer}>
        <input
          readOnly
          className={`${classes.input} ${value !== "" && classes.inputOk}`}
          placeholder={type}
          type="text"
          value={value}
          name="birth_date"
          onChange={(e) => setValue((v) => e.target.value)}
          onClick={togglePanel}
          style={showError ? { border: "1px solid var(--error00)" } : {}}
        />
        <img
          className={classes.icon}
          src={iconHandler(type, showPanel)}
          onClick={togglePanel}
        />
      </div>
      {showPanel === "Day" && type === "Day" && (
        <div
          className={`${classes.panel} ${classes.panelGridDay} ${
            type === "Year" && classes.panelYear
          } `}
        >
          {data.map((el, index) => (
            <div
              key={index}
              className={classes.selections}
              id={el}
              onClick={onChangeHandler}
            >
              {el}
            </div>
          ))}
        </div>
      )}
      {showPanel === "Month" && type === "Month" && (
        <>
          <div
            className={`${classes.panel} ${classes.panelGridMonth} ${
              type === "Year" && classes.panelYear
            } ${classes.web} `}
          >
            {data.map((el, index) => (
              <div
                key={index}
                className={classes.selections}
                id={el.MMMM}
                onClick={onChangeHandler}
              >
                {el.MM}
              </div>
            ))}
          </div>
          <div className={classes.mobile}>
            <SimpleBar
              forceVisible="y"
              autoHide={false}
              className={`${classes.panel} ${classes.panelMonth} `}
              style={{ height: 188, gridTemplateColumns: `repeat(1 ,1fr)` }}
            >
              {data.map((el, index) => (
                <div
                  key={index}
                  className={`${classes.selections}  ${classes.year}`}
                  id={el.MMMM}
                  onClick={onChangeHandler}
                >
                  {el.MM}
                </div>
              ))}
            </SimpleBar>
          </div>
        </>
      )}
      {showPanel === "Year" && type === "Year" && (
        <SimpleBar
          forceVisible="y"
          autoHide={false}
          className={`${classes.panel} ${
            type === "Year" && classes.panelYear
          } `}
          style={{
            gridTemplateColumns: `repeat(${columns},1fr)`,
            height: 188,
          }}
        >
          {data.map((el, index) => (
            <div
              key={index}
              className={`${classes.selections} ${classes.year}`}
              id={el}
              onClick={onChangeHandler}
            >
              {el}
            </div>
          ))}
        </SimpleBar>
      )}
    </div>
  );
};

export default CustomPicker;
