import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import SignUpForm from "../form/SignUpForm";
import SignInForm from "../form/SignInForm";
import ForgotPasswordForm from "../form/ForgotPasswordForm";
import PageLoader from "../../../common/ui/PageLoader";

import classes from "./AuthPage.module.css";

import sideAuthImg from "../../../common/images/sideAuthImg.png";

import { getCustomerEmail } from "../../../api";

const AuthPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [idEmail, setIdEmail] = useState("");
  const [enteredEmail, setEnteredEmail] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [searchParams] = useSearchParams();

  let mode = searchParams.get("mode");
  let id = searchParams.get("id");

  if (mode === null || id === null) {
    mode = "login";
  }

  const handleGetCustomerEmail = async () => {
    try {
      setIsLoading(true);
      let curID = id;
      let response = await getCustomerEmail(curID);
      let curEmail = response.email;
      setIdEmail(curEmail);
      setIsLoading(false);
    } catch (e) {
      console.log(e);

      if (mode === "signup") {
        setShowError(true);
        setErrorMessage(e.message);
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (mode === "signup") {
      handleGetCustomerEmail();
    }
  }, [mode]);

  const getMode = (mode: string) => {
    switch (mode) {
      case "signup":
        return {
          sideText: "Your health experience starts here",
          title: "Sign Up",
          subTitle: "Already have an account?",
          link: "login",
          linkText: "Log in",
        };
      case "forgot-password":
        return {
          sideText: "Welcome back!",
          title: "Forgot Password",
          subTitle:
            "You will receive an email with a provided link to reset your password. Please check your inbox.",
          link: "",
          linkText: "",
        };

      default:
        return {
          sideText: "Welcome back!",
          title: "Sign in",
          subTitle: `${id !== null ? "Don’t have an account?" : ""}`,
          link: `${id !== null ? "signup" : ""}`,
          linkText: `${id !== null ? "Sign up here" : ""}`,
        };
    }
  };

  return (
    <div className={classes.container}>
      <div
        className={classes.side}
        style={{
          backgroundImage: `url('${sideAuthImg}')`,
          backgroundPosition: "top center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          color: "var(--secondary001)",
        }}
      />
      {/*<div className={classes.imgContainer}>*/}
      {/*  <img className={classes.logo} src={logo} />*/}
      {/*  <div className={classes.text}>{getMode(mode).sideText}</div>*/}
      {/*</div>*/}

      <div className={classes.main}>
        {isLoading && <PageLoader />}
        {!isLoading && (
          <div className={classes.inner}>
            <div className={classes.innerScroll}>
              <div className={classes.title}>{getMode(mode).title}</div>
              <div className={classes.titleMobile}>{getMode(mode).title}</div>

              <div className={classes.subTitle}>
                <div>{getMode(mode).subTitle} </div>
                <Link
                  to={`?mode=${getMode(mode).link}&id=${id}`}
                  className={classes.subTitleLink}
                  onClick={() => {
                    setErrorMessage("");
                    setShowError(false);
                  }}
                >
                  {getMode(mode).linkText}
                </Link>
              </div>
              <div className={classes.box}>
                {showError && (
                  <div className={classes.error}>{`*${errorMessage}`}</div>
                )}
              </div>

              {mode === "login" && (
                <SignInForm
                  id={id}
                  setIsLoading={setIsLoading}
                  setShowError={setShowError}
                  setErrorMessage={setErrorMessage}
                />
              )}

              {mode === "signup" && (
                <SignUpForm
                  id={id}
                  enteredEmail={idEmail}
                  setIsLoading={setIsLoading}
                  setShowError={setShowError}
                  setErrorMessage={setErrorMessage}
                  errorMessage={errorMessage}
                />
              )}
              {mode === "forgot-password" && (
                <ForgotPasswordForm
                  enteredEmail={enteredEmail}
                  setEnteredEmail={setEnteredEmail}
                  setIsLoading={setIsLoading}
                  setShowError={setShowError}
                  setErrorMessage={setErrorMessage}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthPage;
