import CardDescription from "./reusable/CardDescription";

import classes from "./Card.module.css";

type Section = {
  HOW_TO_IMPROVE: string;
  HOW_TO_IMPROVE_TEXT: string;
  INDEX: number;
  TITLE1: string;
  TITLE2: string;
  WHY_IT_MATTERS: string;
  WHY_IT_MATTERS_TEXT: string;
};

interface Props {
  data: {
    DESCRIPTION: string;
    INDEX: number;
    SECTIONS: Section[];
    TITLE: string;
  };
  index: number;
  width: string;
  source: string;
  innerWidth: string;
  borderColor: string;
  isRestoreReport: boolean;
}

const Card = ({
  data,
  index,
  width,
  source,
  innerWidth,
  borderColor,
  isRestoreReport,
}: Props): JSX.Element => {
  const selectedData = data.SECTIONS.filter((item) => item.INDEX === index)[0];

  return (
    <div
      className={classes.container}
      style={{ marginBottom: index === 1 ? "10px" : "" }}
    >
      <img
        className={classes.imgContainer}
        src={source}
        style={{ width: width }}
        alt="card-container"
      />
      <div className={classes.innerContainer} style={{ width: innerWidth }}>
        <div className={classes.header} style={{ borderColor: borderColor }}>
          <span className={classes.title1}>{selectedData.TITLE1}</span>
          <span className={classes.title2}>{selectedData.TITLE2}</span>
        </div>
        <ul className={classes.listItems}>
          <li className={classes.sub}>
            <CardDescription
              title={selectedData.WHY_IT_MATTERS}
              description={selectedData.WHY_IT_MATTERS_TEXT}
              index={selectedData.INDEX}
              useLargerFont={false}
            />
          </li>
          <li className={classes.sub}>
            <CardDescription
              title={selectedData.HOW_TO_IMPROVE}
              description={selectedData.HOW_TO_IMPROVE_TEXT}
              index={selectedData.INDEX}
              useLargerFont={false}
              showDescriptionInList={isRestoreReport}
              color={"var(--primary100)"}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Card;
