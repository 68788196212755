import classes from "./LifestyleProgressBar.module.css";
import { useState } from "react";

type Props = {
  value: number;
};

const LifestyleProgressBar = ({ value }: Props) => {
  const [showText, setShowText] = useState(false);

  const handleMouseEnter = () => {
    setShowText(true);
  };
  const handleMouseLeave = () => {
    setShowText(false);
  };
  // @ts-ignore
  return (
    <div
      className={classes.container}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={classes.title}>Lifestyle</div>
      <div className={classes.bar}>
        <div className={classes.inner}>
          <div className={classes.fill} style={{ width: `${value}%` }}></div>
        </div>
        <div className={classes.pointers}>
          {Array.from({ length: 33 }, (_, i) => (
            <div
              key={i}
              className={`${classes.pointer} ${classes.point__q}`}
              style={{ "--i": `${i + 1}` } as React.CSSProperties}
            ></div>
          ))}
        </div>
        {showText && (
          <div className={classes.descriptionPanelContainer}>
            <div className={classes.descriptionPanel}>
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="8.48535"
                  width="12"
                  height="12"
                  transform="rotate(45 8.48535 0)"
                  fill="#1B1F22"
                />
              </svg>
            </div>
            <div className={classes.descriptionPanelText}>
              <span className={classes.text1}>{`${Math.floor(value)}%`}</span>{" "}
              <span className={classes.text2}>
                complete of the lifestyle questionnaire! Keep it up!
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LifestyleProgressBar;
