import { useSelector } from "react-redux";

import Quest from "../layout/Quest";
import { useEffect } from "react";
import Layout from "../layout/Layout";
import { useNavigate } from "react-router-dom";
import LifestyleQuest from "../layout/LifestyleQuest";
import { RootStateStepper } from "../../store/stepper-slice";

const LifestyleQuestionnaire = () => {
  const stepper = useSelector((state: RootStateStepper) => state.stepper.step);
  const navigate = useNavigate();

  useEffect(() => {
    const infoJSON = localStorage.getItem("Info");

    const info = JSON.parse(infoJSON);
    if (info === null || info.pnoe_user_id === "") {
      navigate("/auth");
    }
  }, [stepper]);

  return (
    <Layout>
      <LifestyleQuest />
    </Layout>
  );
};

export default LifestyleQuestionnaire;
