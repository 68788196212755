import classes from "./BtnForm.module.css";

import maleIcon from "../../../common/images/male.png";
import femaleIcon from "../../../common/images/female.png";
import checked from "../../../common/images/checked.png";

const BtnForm = ({ data, value, category, answer, ...props }) => {
  let content;
  if (category === "mass_measurement_unit") {
    content = (
      <div className={classes.symbol}>
        {`${data.lengthUnit} / ${data.massUnit}`}
      </div>
    );
  } else if (category === "gender") {
    content = (
      <img
        src={data.label === "Male" ? maleIcon : femaleIcon}
        className={`${
          data.label === "Male" ? classes.btnImgMale : classes.btnImgFemale
        }`}
      />
    );
  }
  return (
    <label
      className={`${classes.btn} ${value === +answer && classes.btnSelected}`}
    >
      <div className={classes.iconContainer}>
        {content}
        {value === +answer && (
          <img className={classes.btnChecked} src={checked} />
        )}
      </div>
      <input
        className={classes.btnInput}
        type="radio"
        value={value}
        {...props}
      />
      {data.label}
    </label>
  );
};

export default BtnForm;
