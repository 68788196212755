import classes from "./RestoreCustomWellnessPlan.module.css";
import ColdWebRestore from "../images/restore/ColdWebRestore.jpg";
import saunaRestore from "../images/restore/saunaRestore.png";
import compressionRestore from "../images/restore/compressionRestore.png";
import ivDripRestore from "../images/restore/ivDripRestore.png";
import restore_logo from "../images/restore_logo.png";
import o2Restore from "../images/restore/o2Restore.jpg";
import redLightTherapyRestore from "../images/restore/redLightTherapyRestore.png";

const DUMMY_DATA = [
  {
    ROW: 1,
    VALUES: [
      { INDEX: 0, VALUE: "" },
      {
        INDEX: 1,
        VALUE: "WEEK 1",
      },
      {
        INDEX: 2,
        VALUE: "WEEK 2",
      },
      {
        INDEX: 3,
        VALUE: "WEEK 3",
      },
      {
        INDEX: 4,
        VALUE: "WEEK 4",
      },
    ],
  },
  {
    ROW: 2,
    VALUES: [
      { INDEX: 0, VALUE: "" },
      {
        INDEX: 1,
        VALUE: "",
      },
      {
        INDEX: 2,
        VALUE: "",
      },
      {
        INDEX: 3,
        VALUE: " ",
      },
      {
        INDEX: 4,
        VALUE: "",
      },
    ],
  },
  {
    ROW: 3,
    VALUES: [
      { INDEX: 0, VALUE: "" },
      {
        INDEX: 1,
        VALUE: "",
      },
      {
        INDEX: 2,
        VALUE: "",
      },
      {
        INDEX: 3,
        VALUE: " ",
      },
      {
        INDEX: 4,
        VALUE: "",
      },
    ],
  },
  {
    ROW: 4,
    VALUES: [
      { INDEX: 0, VALUE: "" },
      {
        INDEX: 1,
        VALUE: "",
      },
      {
        INDEX: 2,
        VALUE: "",
      },
      {
        INDEX: 3,
        VALUE: " ",
      },
      {
        INDEX: 4,
        VALUE: "",
      },
    ],
  },
  {
    ROW: 5,
    VALUES: [
      { INDEX: 0, VALUE: "" },
      {
        INDEX: 1,
        VALUE: "",
      },
      {
        INDEX: 2,
        VALUE: "",
      },
      {
        INDEX: 3,
        VALUE: " ",
      },
      {
        INDEX: 4,
        VALUE: "",
      },
    ],
  },
  {
    ROW: 6,
    VALUES: [
      { INDEX: 0, VALUE: "" },
      {
        INDEX: 1,
        VALUE: "",
      },
      {
        INDEX: 2,
        VALUE: "",
      },
      {
        INDEX: 3,
        VALUE: " ",
      },
      {
        INDEX: 4,
        VALUE: "",
      },
    ],
  },
];

const RestoreCustomWellnessPlan = () => {
  return (
    <div className={classes.container}>
      <div className={classes.mainContainer}>
        <div className={classes.title}>
          __________________'s Custom Wellness Plan
        </div>
        <div className={classes.subTitle}>
          Athletic Recovery | Weight Management | Pain Management
        </div>
        <div className={classes.subTitle}>
          Proactive Wellness | Boost Immune System | Improve Focus/Mental Health
        </div>
        <div>
          <ul className={classes.rowItems}>
            {DUMMY_DATA.map((row: any, index: number) => (
              <li
                className={classes.rowItem}
                key={row.ROW}
                style={{ height: index === 0 ? "65px" : "55px" }}
              >
                <ul className={classes.cellItem}>
                  {row.VALUES.map((cell: any, index: number) => (
                    <li
                      className={classes.cell}
                      key={cell.INDEX}
                      style={{ flex: index === 0 ? 1 : 2 }}
                    >
                      {cell.VALUE}
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
        <div className={classes.imgContainer} style={{ top: "113px" }}>
          <img
            src={ColdWebRestore}
            alt="Restore Icon"
            style={{ width: "24px", height: "24px" }}
          />
          <div style={{ textAlign: "center" }}>Whole Body Cryotherapy</div>
        </div>
        <div className={classes.imgContainer} style={{ top: "170px" }}>
          <img
            src={saunaRestore}
            alt="Restore Icon"
            style={{ width: "24px", height: "24px" }}
          />
          <div style={{ textAlign: "center" }}>Infrared Sauna</div>
        </div>
        <div className={classes.imgContainer} style={{ top: "225px" }}>
          <img
            src={redLightTherapyRestore}
            alt="Restore Icon"
            style={{ width: "24px", height: "24px" }}
          />
          <div style={{ textAlign: "center" }}>Red Light Therapy</div>
        </div>
        <div className={classes.imgContainer} style={{ top: "280px" }}>
          <img
            src={compressionRestore}
            alt="Restore Icon"
            style={{ width: "24px", height: "24px" }}
          />
          <div style={{ textAlign: "center" }}>Compression</div>
        </div>
        <div className={classes.imgContainer} style={{ top: "335px" }}>
          <img
            src={ivDripRestore}
            alt="Restore Icon"
            style={{ width: "24px", height: "24px" }}
          />
          <div style={{ textAlign: "center" }}>IV Drip</div>
        </div>
        <div className={classes.imgContainer} style={{ top: "390px" }}>
          <img
            src={o2Restore}
            alt="Restore Icon"
            style={{ width: "24px", height: "24px" }}
          />
          <div style={{ textAlign: "center" }}>
            Mild Hyperbaric Oxygen Therapy
          </div>
        </div>
        <div className={classes.notesText}>Notes:</div>
      </div>
      <div className={classes.sideContainer}>
        <div className={classes.topSideContainer}>
          <img
            alt="restore logo"
            src={restore_logo}
            className={classes.logoRestore}
            style={{
              width: "90px",
              alignSelf: "flex-end",
              marginBottom: "32px",
            }}
          />
          <div style={{ marginTop: "1px" }}>
            Here's your custom plan designed to help you feel your best, and do
            more of what you love.
          </div>
          <div style={{ marginTop: "8px" }}>
            After your first month, we'll work with you to tailor your wellness
            plan based on your feedback.
          </div>
        </div>
        <div
          className={classes.sideContainerText}
          style={{ color: "#3C91C3", marginTop: "20px" }}
        >
          Do More Goal:
        </div>
        <div
          className={classes.sideContainerText}
          style={{ marginTop: "60px" }}
        >
          Membership:
        </div>
        <div
          className={classes.sideContainerText}
          style={{ marginTop: "60px" }}
        >
          Package:
        </div>
        <div
          className={classes.sideContainerTextVersion}
          style={{ marginTop: "60px" }}
        >
          Version 11.13
        </div>
      </div>
    </div>
  );
};

export default RestoreCustomWellnessPlan;
