import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomPicker from "../reusable/CustomPicker";
import { MAX_AGE } from "../../constant";
import classes from "./CustomDatePicker.module.css";

import { answersActions } from "../../store/answers-slice";

//initial values for pickers (day, month, year)
const days = Array.from(new Array(31), (v, i) => 1 + i);
const months = [
  { index: 1, MM: "Jan", MMMM: "January" },
  { index: 2, MM: "Feb", MMMM: "February" },
  { index: 3, MM: "Mar", MMMM: "March" },
  { index: 4, MM: "Apr", MMMM: "April" },
  { index: 5, MM: "May", MMMM: "May" },
  { index: 6, MM: "Jun", MMMM: "June" },
  { index: 7, MM: "Jul", MMMM: "July" },
  { index: 8, MM: "Aug", MMMM: "August" },
  { index: 9, MM: "Sep", MMMM: "September" },
  { index: 10, MM: "Oct", MMMM: "October" },
  { index: 11, MM: "Nov", MMMM: "November" },
  { index: 12, MM: "Dec", MMMM: "December" },
];
const curYear = new Date().getFullYear();
const years = Array.from(new Array(MAX_AGE), (v, i) => curYear - i);

const CustomDatePicker = () => {
  const dispatch = useDispatch();
  const stepper = useSelector((state) => state.stepper.step);
  const formData = useSelector((state) => state.stepper.formData);
  const answers = useSelector((state) => state.answers.biometricQuest);

  const [error, setError] = useState("");

  let curAnswer = answers.myAnswers[formData[stepper - 1].category];

  let initialState;
  if (curAnswer) {
    initialState = {
      day: new Date(curAnswer).getDate(),
      month: months[new Date(curAnswer).getMonth()].MMMM,
      year: new Date(curAnswer).getFullYear(),
    };
  } else {
    initialState = {
      day: "",
      month: "",
      year: "",
    };
  }

  const [day, setDay] = useState(initialState.day);
  const [month, setMonth] = useState(initialState.month);
  const [year, setYear] = useState(initialState.year);
  const [showPanel, setShowPanel] = useState("");

  Number.prototype.padLeft = function (n, str) {
    return Array(n - String(this).length + 1).join(str || "0") + this;
  };

  const validateDate = (day, month, year, answer) => {
    if (year > new Date().getFullYear() - 6) {
      setError("You are too young!");
    } else if (
      (month === 4 || month === 6 || month === 9 || month === 11) &&
      day === 30
    ) {
      setError("Please enter a valid day");
    } else if (month === 2 && year % 4 === 0 && day > 28) {
      setError("Please enter a valid day");
    } else if (month === 2 && year % 4 !== 0 && day > 27) {
      setError("Please enter a valid day");
    } else {
      setError("");
      dispatch(
        answersActions.updateBiometricAnswers({
          [formData[stepper - 1].category]: answer,
        })
      );
    }
  };

  useEffect(() => {
    if (day && month && year) {
      const indexDay = days.findIndex((v) => v === +day);
      const curMonth = months.find((v) => v.MMMM === month);

      let answer =
        year +
        "-" +
        curMonth.index.padLeft(2, "0") +
        "-" +
        (indexDay + 1).padLeft(2, "0");

      validateDate(indexDay, curMonth.index, year, answer);
    }
  }, [day, dispatch, formData, month, stepper, year, error]);

  const closePanel = () => {
    setShowPanel("");
  };

  const handleFilterDays = (month) => {
    let filterDays;
    if (
      month === months[3].MMMM ||
      month === months[5].MMMM ||
      month === months[8].MMMM ||
      month === months[10].MMMM
    ) {
      filterDays = days.slice(0, -1);
    } else if (month === months[1].MMMM && year % 4 === 0 && year) {
      filterDays = days.slice(0, -2);
    } else if (month === months[1].MMMM) {
      filterDays = days.slice(0, -3);
    } else {
      filterDays = days;
    }
    return filterDays;
  };

  return (
    <div>
      <div className={classes.container}>
        <CustomPicker
          type="Day"
          data={handleFilterDays(month)}
          value={day}
          setValue={setDay}
          showPanel={showPanel}
          setShowPanel={setShowPanel}
          closePanel={closePanel}
          columns={7}
          showError={!!error}
        />
        <CustomPicker
          type="Month"
          data={months}
          value={month}
          setValue={setMonth}
          showPanel={showPanel}
          setShowPanel={setShowPanel}
          closePanel={closePanel}
          columns={3}
          showError={!!error}
        />
        <CustomPicker
          type="Year"
          data={years}
          value={year}
          setValue={setYear}
          showPanel={showPanel}
          setShowPanel={setShowPanel}
          closePanel={closePanel}
          columns={1}
          showError={!!error}
        />
      </div>
      {/*<div className={classes.containerMobile}>*/}
      {/*    <CustomDatePickerMobile*/}
      {/*        type='day'*/}
      {/*        data={handleFilterDays(month)}*/}
      {/*        value={day}*/}
      {/*        setValue={setDay}*/}
      {/*        showError={!!error}*/}
      {/*    />*/}
      {/*    <CustomDatePickerMobile*/}
      {/*        type='month'*/}
      {/*        data={months.map((el) => el.MMMM)}*/}
      {/*        value={month}*/}
      {/*        setValue={setMonth}*/}
      {/*        showError={!!error}*/}
      {/*    />*/}
      {/*    <CustomDatePickerMobile*/}
      {/*        type='year'*/}
      {/*        data={years}*/}
      {/*        value={year}*/}
      {/*        setValue={setYear}*/}
      {/*        showError={!!error}*/}
      {/*    />*/}
      {/*</div>*/}

      {!!error && <div className={classes.error}>{error}</div>}
    </div>
  );
};

export default CustomDatePicker;
