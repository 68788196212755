import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { getReportData } from "../../api";
import PageLoader from "../components/UI/PageLoader";
import GeneratePDF from "../components/UI/GeneratePDF.js";
import ReportNotFound from "./ReportNotFound";

import ReportAMR from "./ReportAMR";
import ReportRMR from "./ReportRMR";

import classes from "./Report.module.css";
import StressReport from "./StressReport";
import CustomReportRMR from "./CustomReportRMR";
import CustomReportAMR from "./CustomReportAMR";
import html2canvas from "html2canvas";

const Report = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [isCreatingPDF, setIsCreatingPDF] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let id = searchParams.get("id");
  let type = searchParams.get("type");
  let lang = searchParams.get("lang");
  let isNativeApp = searchParams.get("isNativeApp");
  let isPlatformApp = searchParams.get("P");

  // console.log("isNative", isNativeApp, "isPlatform", isPlatformApp);

  useEffect(() => {
    getReportData(
      id,
      type,
      lang,
      setData,
      setIsLoading,
      setError,
      navigate,
      isPlatformApp
    );
  }, [id, type, lang]);

  const svgConverter = useCallback(
    async (content: string, result: string, imgWidth: number) => {
      const element = document.getElementById(content),
        canvas = await html2canvas(element, {
          useCORS: true,
          backgroundColor: "transparent",
          allowTaint: true,
          scale: 1.5,
        });
      const img = new Image();
      img.src = canvas.toDataURL("image/jpg");
      img.width = imgWidth;
      // @ts-ignore
      document.getElementById(result).appendChild(img);
    },
    []
  );

  return (
    <React.Fragment>
      {isLoading && <PageLoader />}
      {data && isNativeApp !== "1" && (
        <GeneratePDF
          userName={
            data.PAGES.find((page: any) => page.INDEX === 1).SUBJECT_NAME
          }
          type={type}
          setIsCreatingPDF={setIsCreatingPDF}
        />
      )}
      {error && <ReportNotFound error={error} />}
      <div id="content" className={classes.content}>
        {data &&
          (type === "10" || type === "12" || type === "14") &&
          data.TRAINER_MAIL === "rick@millerandeverton.co.uk" && (
            <CustomReportAMR
              data={data}
              svgConverter={svgConverter}
              reportType={type}
            />
          )}
        {data &&
          (type === "10" || type === "12" || type === "14") &&
          data.TRAINER_MAIL !== "rick@millerandeverton.co.uk" && (
            <ReportAMR
              data={data}
              svgConverter={svgConverter}
              reportType={type}
              isCreatingPDF={isCreatingPDF}
              customerMail={data.TRAINER_MAIL}
            />
          )}
        {data &&
          (type === "11" || type === "13" || type === "15") &&
          data.TRAINER_MAIL === "rick@millerandeverton.co.uk" && (
            <CustomReportRMR
              data={data}
              svgConverter={svgConverter}
              reportType={type}
            />
          )}
        {data &&
          (type === "11" || type === "13" || type === "15") &&
          data.TRAINER_MAIL !== "rick@millerandeverton.co.uk" && (
            <ReportRMR
              data={data}
              svgConverter={svgConverter}
              reportType={type}
              isCreatingPDF={isCreatingPDF}
            />
          )}
        {/*{data && type === "16" && (*/}
        {/*  <StressReport*/}
        {/*    data={data}*/}
        {/*    svgConverter={svgConverter}*/}
        {/*    reportType={type}*/}
        {/*  />*/}
        {/*)}*/}
      </div>
    </React.Fragment>
  );
};

export default Report;
