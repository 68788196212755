import Layout from "../../components/reusable/Layout";
import ChartBars from "../../components/reusable/ChartBars";
import CardDescription from "../../components/reusable/CardDescription";
import SuggestionCard from "../../components/SuggestionCard";
import Qrcode from "../../components/reusable/Qrcode";

import classes from "./MetricPage.module.css";
import CustomSuggestionCard from "../../components/CustomSuggestionCard";

type Tab = { INDEX: number; NAME: string; VALUE: number };

type improveArea = { INDEX: number; TEXT: string; TITLE: string };

type Metric = {
  HOW_IT_IS_MEASURED: string;
  HOW_IT_IS_MEASURED_TEXT: string;
  INDEX: number;
  SUGGESTIONS: { IMPROVE_AREAS: improveArea[]; INDEX: number; TITLE: string }[];
  TITLE1: string;
  TITLE2: string;
  TOP: string;
  VALUE: number;
  WHAT_IT_IS: string;
  WHAT_IT_IS_TEXT: string;
  WHY_ITS_IMPORTANT: string;
  WHY_ITS_IMPORTANT_TEXT: string;
  RECCOMENDATIONS_TO_IMPROVE: string;
  SOURCES?: any;
  METRIC_TYPE: number;
};

interface Props {
  data: Metric[];
  navBarData: Tab[];
  qrCodeData: {
    FOOTER1: string;
    HEADER1: string;
    HEADER2: string;
    QRCODE: string;
  };
  //     {
  //   DESCRIPTION: string;
  //   INDEX: number;
  //   METRICS: Metric[];
  //
  //   SOURCES: any;
  //   RISK_METRICS: Metric[];
  //   PERFORMANCE_METRICS: Metric[];
  //   QRCODE: {
  //     FOOTER1: string;
  //     HEADER1: string;
  //     HEADER2: string;
  //     QRCODE: string;
  //   };
  //   TITLE: string;
  //   TITLE_PERFORMANCE_METRICS: string;
  // };
}

const MetricPage = ({ data, navBarData, qrCodeData }: Props): JSX.Element => {
  return (
    <>
      {data.map((metric) => (
        <Layout key={`${metric.INDEX} ${metric.TITLE1}`}>
          <div className={classes.chart}>
            <ChartBars
              id={metric.METRIC_TYPE}
              title1={metric.TITLE1}
              value={metric.VALUE}
              title2={metric.TITLE2}
              labelsIsVisible={true}
              useLargerFont={true}
              customGap={true}
              labels={navBarData.slice(0, -1)}
              showIcon={true}
              iconLabel={metric.TOP}
            />
          </div>

          <ul className={classes.inner}>
            <li className={classes.sub}>
              <CardDescription
                title={metric.HOW_IT_IS_MEASURED}
                description={metric.HOW_IT_IS_MEASURED_TEXT}
                useLargerFont={true}
                index={metric.INDEX}
              />
            </li>
            <li className={classes.sub}>
              <CardDescription
                title={metric.WHY_ITS_IMPORTANT}
                description={metric.WHY_ITS_IMPORTANT_TEXT}
                useLargerFont={true}
                index={metric.INDEX}
              />
            </li>
          </ul>
          <div className={classes.card_container}>
            <div className={classes.suggestion_tables_title}>
              {metric.RECCOMENDATIONS_TO_IMPROVE}
            </div>
            <ul className={classes.suggestion_tables}>
              {metric.SUGGESTIONS.map((item, index: number) =>
                item.INDEX > 3 ? (
                  <CustomSuggestionCard key={index} data={item} />
                ) : (
                  <SuggestionCard key={index} data={item} />
                )
              )}
            </ul>
          </div>
          <Qrcode data={qrCodeData} sources={metric.SOURCES} />
        </Layout>
      ))}
    </>
  );
};

export default MetricPage;
