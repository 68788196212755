import IntroPage from "./questionnaire/components/pages/IntroPage";
import ErrorPage from "./questionnaire/components/pages/ErrorPage";
import SuccessPageBiometric from "./questionnaire/components/pages/SuccessPageBiometric";
import AuthPage from "./authPage/components/pages/AuthPage";
import ResetPasswordPage from "./authPage/components/pages/ReserPasswordPage";
import DeviceOrientation, { Orientation } from "react-screen-orientation";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import BiometricQuestionnaire from "./questionnaire/components/pages/BiometricQuestionnaire";
import LifestyleQuestionnaire from "./questionnaire/components/pages/LifestyleQuestionnaire";

import classes from "./App.module.css";
import backgroundImg from "./common/images/backgroundImg.png";
import logo from "./common/images/logo.png";
import { useState, useEffect, useCallback } from "react";
import Report from "./reports/reports/Report";

import "./common/fonts/AktivGroteskEx_A_Bd-normal";
import "./common/fonts/AktivGroteskEx_A_Lt-normal";
import "./common/fonts/AktivGroteskEx_A_Md-normal";
import "./common/fonts/AktivGroteskEx_A_Rg-normal";
import "./common/fonts/AktivGroteskEx_A_XBd-normal";
import "./common/fonts/Poppins-Light-normal";
import SuccessPageLifestyle from "./questionnaire/components/pages/SuccessPageLifestyle";

const router = createBrowserRouter([
  { path: "/auth", element: <AuthPage /> },
  { path: "/reset-password", element: <ResetPasswordPage /> },
  { path: "/intro", element: <IntroPage /> },
  { path: "/biometric-questionnaire", element: <BiometricQuestionnaire /> },
  {
    path: "/lifestyle-questionnaire",
    element: (
      <>
        <Helmet>
          <title>PNOĒ - Lifestyle Questionnaire</title>
        </Helmet>
        <LifestyleQuestionnaire />
      </>
    ),
  },
  { path: "/success-biometric", element: <SuccessPageBiometric /> },
  { path: "/success-lifestyle", element: <SuccessPageLifestyle /> },
  { path: "/error", element: <ErrorPage /> },
  {
    path: "/reports",
    element: (
      <>
        <Helmet>
          <title>PNOĒ - Report</title>
        </Helmet>
        <Report />
      </>
    ),
  },
  {
    path: "*",
    element: <AuthPage />,
  },
]);

function App() {
  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--app-height", `${window.innerHeight}px`);
  };
  window.addEventListener("resize", appHeight);
  appHeight();

  const appWidth = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--app-width", `${window.innerWidth}px`);
  };
  window.addEventListener("resize", appWidth);
  appWidth();

  useEffect(() => {
    appHeight();
    appWidth();
  }, []);

  const isLandscape = () =>
      window.matchMedia("(orientation:landscape)").matches,
    [orientation, setOrientation] = useState(
      isLandscape() ? "landscape" : "portrait"
    ),
    onWindowResize = () => {
      clearTimeout(window.resizeLag);
      window.resizeLag = setTimeout(() => {
        delete window.resizeLag;
        setOrientation(isLandscape() ? "landscape" : "portrait");
      }, 200);
    };

  useEffect(
    () => (
      onWindowResize(),
      window.addEventListener("resize", onWindowResize),
      () => window.removeEventListener("resize", onWindowResize)
    ),
    []
  );

  return (
    <>
      {!isMobile && (
        <div className={classes.app}>
          <RouterProvider router={router}></RouterProvider>
        </div>
      )}
      {isMobile && (
        <DeviceOrientation lockOrientation={"portrait"}>
          <Orientation orientation="portrait" alwaysRender={false}>
            <div className={classes.app}>
              <RouterProvider router={router}></RouterProvider>
            </div>
          </Orientation>

          <Orientation orientation="landscape" alwaysRender={false}>
            <div className={classes.landscapeMode}>
              <div
                className={classes.container}
                style={{
                  backgroundImage: `url('${backgroundImg}')`,
                  backgroundPosition: "top",
                  backgroundSize: "cover",
                  height: "var(--app-height)",

                  color: "var(--secondary001)",
                }}
              >
                <div className={classes.imgContainer}>
                  <img className={classes.logo} src={logo} />
                </div>
                <div className={classes.text}>
                  This page is not available in landscape version
                </div>
                <div className={classes.subText}>Please rotate your device</div>
              </div>
            </div>
          </Orientation>
        </DeviceOrientation>
      )}
    </>
  );
}

export default App;
