import CardDescription from "./reusable/CardDescription";
import CustomCard from "./reusable/CustomCard";

import classes from "./SuggestionCard.module.css";

import LightWebRestore from "../images/restore/LightWebRestore.jpg";
import ColdWebRestore from "../images/restore/ColdWebRestore.jpg";
import HeatWebRestore from "../images/restore/HeatWebRestore.jpg";
import HydrationWebRestore from "../images/restore/HydrationWebRestore.jpg";
import RestWebRestore from "../images/restore/RestWebRestore.jpg";
import CardDescriptionRestore from "./reusable/CardDescriptionRestore";

type improveArea = {
  INDEX: number;
  TEXT: string;
  TITLE: string;
  SUBTITLE?: string;
  VALUE?: number;
};

interface Props {
  data: { IMPROVE_AREAS: improveArea[]; INDEX: number; TITLE: string };
}

const CustomSuggestionCard = ({ data }: Props): JSX.Element => {
  let icon;
  switch (data.INDEX) {
    case 4:
    // icon = ColdWebRestore;
    // break;
    case 5:
    // icon = HydrationWebRestore;
    // break;
    case 6:
    // icon = LightWebRestore;
    // break;
    case 7:
    // icon = HeatWebRestore;
    // break;
    case 8:
    // icon = RestWebRestore;
    // break;
    default:
      icon = undefined;
  }

  return (
    <CustomCard cardPadding="10px 14px">
      {
        <div className={classes.title} style={{ fontSize: "18px" }}>
          <div>{data.TITLE}</div>
        </div>
      }

      <div className={classes.description}>
        <ul className={classes.columns}>
          {data.IMPROVE_AREAS.slice(0, 1).map((item) => (
            <li key={100} className={classes.sub} style={{ flex: 1 }}>
              <CardDescriptionRestore
                title={item.TITLE}
                subtitle={item.SUBTITLE || ""}
                description={item.TEXT}
                showIcon={false}
                valueIcon={item.VALUE}
                index={item.INDEX}
                customMargin={0}
                usecustomStyle={true}
                showDescriptionInList={true}
              />
            </li>
          ))}
          {data.IMPROVE_AREAS.slice(1).map((item, index: number) => (
            <li key={index} className={classes.sub} style={{ flex: 1 }}>
              <CardDescriptionRestore
                title={item.TITLE}
                subtitle={item.SUBTITLE || ""}
                description={item.TEXT}
                showIcon={false}
                valueIcon={item.VALUE}
                index={item.INDEX}
                customMargin={0}
                usecustomStyle={true}
                showDescriptionInList={true}
              />
            </li>
          ))}
        </ul>
      </div>
    </CustomCard>
  );
};

export default CustomSuggestionCard;
