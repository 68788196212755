import classes from "./DescriptionPanel.module.css";
import { bool } from "yup";

type Props = {
  data: any;
  showText: boolean;
  handleMouseEnter: any;
  handleMouseLeave: any;
};

const DescriptionPanel = ({
  data,
  showText,
  handleMouseEnter,
  handleMouseLeave,
}: Props) => {
  return (
    <div
      className={classes.descriptionPanelContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        className={classes.infoIcon}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM6.80078 7.99438C6.80078 7.36939 7.36769 6.89755 7.9823 7.011L8.38264 7.08489C8.85691 7.17244 9.20112 7.586 9.20112 8.06828V12C9.20112 12.6245 8.63508 13.0962 8.02082 12.9836L7.62048 12.9102C7.14565 12.8232 6.80078 12.4094 6.80078 11.9266V7.99438ZM6.60156 4.59995C6.60156 3.82675 7.22836 3.19995 8.00156 3.19995C8.77476 3.19995 9.40156 3.82675 9.40156 4.59995C9.40156 5.37315 8.77476 5.99995 8.00156 5.99995C7.22836 5.99995 6.60156 5.37315 6.60156 4.59995Z"
          fill="var(--secondary01)"
        />
      </svg>
      {showText && (
        <div className={classes.descriptionPanel}>
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classes.pointer}
          >
            <rect
              x="8.48535"
              width="12"
              height="12"
              transform="rotate(45 8.48535 0)"
              fill="var(--primary02)"
            />
          </svg>
        </div>
      )}
    </div>
  );
};

export default DescriptionPanel;
