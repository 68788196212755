import classes from "./SelectionListForm.module.css";
// @ts-ignore
import { answersActions } from "../../store/answers-slice";

type Props = {
  data: any;
  selections: number;
  updateUserSelections: (option: number, userSelections: number) => void;
};

const SelectionListForm = ({
  data,
  selections,
  updateUserSelections,
}: Props) => {
  return (
    <div
      className={classes.container}
      onClick={() => updateUserSelections(data.index, selections)}
    >
      <label className={classes.text}>
        {(selections & data.index) === data.index ? (
          <div
            className={classes.checkboxContainer}
            style={{
              backgroundColor: "var(--secondary03)",
              border: "1px solid var(--secondary03)",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="10"
              viewBox="0 0 12 10"
              fill="none"
            >
              <path
                d="M10.725 0.725L3.75 7.7L1.275 5.225C0.975 4.925 0.525 4.925 0.225 5.225C-0.075 5.525 -0.075 5.975 0.225 6.275L3.225 9.275C3.375 9.425 3.525 9.5 3.75 9.5C3.975 9.5 4.125 9.425 4.275 9.275L11.775 1.775C12.075 1.475 12.075 1.025 11.775 0.725C11.475 0.425 11.025 0.425 10.725 0.725Z"
                fill={"var(--primary00)"}
              />
            </svg>
          </div>
        ) : (
          <div className={classes.checkboxContainer}></div>
        )}

        <span className={classes.label}>{data.label}</span>
      </label>
    </div>
  );
};

export default SelectionListForm;
