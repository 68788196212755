import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

import ResetPasswordForm from "../form/ResetPasswordForm";
import PageLoader from "../../../common/ui/PageLoader";

import classes from "./AuthPage.module.css";

import sideAuthImg from "../../../common/images/sideAuthImg.png";

const ResetPasswordPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchParams] = useSearchParams();

  const resetToken = searchParams.get("rp");

  return (
    <div className={classes.container}>
      <div
        className={classes.side}
        style={{
          backgroundImage: `url('${sideAuthImg}')`,
          backgroundPosition: "top center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          color: "var(--secondary001)",
        }}
      >
        {/*<div className={classes.imgContainer}>*/}
        {/*  <img className={classes.logo} src={logo} />*/}
        {/*  <div className={classes.text}>{getMode(mode).sideText}</div>*/}
        {/*</div>*/}
      </div>
      <div className={classes.main}>
        {isLoading && <PageLoader />}
        {!isLoading && (
          <div className={classes.inner}>
            <div className={classes.innerScroll}>
              <div className={classes.title}>{`${
                isPasswordUpdated ? "Password Updated" : "New Credentials"
              }`}</div>
              <div className={classes.titleMobile}>{`${
                isPasswordUpdated ? "Password Updated" : "New Credentials"
              }`}</div>
              <div className={classes.subTitle}>
                {isPasswordUpdated && (
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM7 11.4L3.6 8L5 6.6L7 8.6L11 4.6L12.4 6L7 11.4Z"
                      fill="#1EAF72"
                    />
                  </svg>
                )}
                <div>
                  {`${
                    isPasswordUpdated
                      ? "Your Password has been updated"
                      : "Please enter a new password"
                  }`}{" "}
                </div>
              </div>
              <div className={classes.box}>
                {showError && (
                  <div className={classes.error}>{`*${errorMessage}`}</div>
                )}
              </div>
              <ResetPasswordForm
                isPasswordUpdated={isPasswordUpdated}
                setIsPasswordUpdated={setIsPasswordUpdated}
                setIsLoading={setIsLoading}
                setShowError={setShowError}
                setErrorMessage={setErrorMessage}
                resetToken={resetToken}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordPage;
