import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { GetUser, LoginUser, QuestionnaireCompleted } from "../../../api";

import TextInput from "../reusable/TextInput";
import Button from "../../../common/ui/Button";

import classes from "./SignUpForm.module.css";
import { useNavigate } from "react-router-dom";

import { answersActions } from "../../../questionnaire/store/answers-slice";
import { stepperActions } from "../../../questionnaire/store/stepper-slice";

const SignInForm = ({ id, setIsLoading, setErrorMessage, setShowError }) => {
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const handleShowPassword = () => {
    setShowPassword(() => !showPassword);
  };

  const handleLogin = async (userData) => {
    try {
      let response = await LoginUser(userData);

      const { email, firstName, lastName, id } = response;

      const paramsReports = JSON.parse(localStorage.getItem("paramReport"));

      if (paramsReports) {
        const { idReport, type, lang } = paramsReports;
        navigate(`/reports?id=${idReport}&type=${type}&lang=${lang}`);
      } else {
        const info = JSON.stringify({
          email: email,
          firstName: firstName,
          lastName: lastName,
          pnoe_user_id: id,
        });

        localStorage.setItem("Info", info);
        await handleIsQuestionnaireCompleted();
      }
    } catch (e) {
      setErrorMessage(e.message);
      setShowError(true);
    }
  };

  const handleIsQuestionnaireCompleted = async () => {
    try {
      let response = await QuestionnaireCompleted(navigate, true);

      dispatch(
        stepperActions.handleHasLifestyleQuestionnaireAccess(
          response.data.has_lifestyle_questionnaire_access
        )
      );
      dispatch(
        stepperActions.handleLifestyleQuestionnaireCompleted(
          response.data.lifestyle_questionnaire_completed
        )
      );
      if (response.data.biometrics_questionnaire_completed) {
        navigate("/success-biometric");
      } else {
        const notes = JSON.parse(localStorage.getItem("notes"));

        // dispatch(answersActions.updateAnswers({ ...notes }));
        navigate("/intro");
      }

      setShowError(false);
    } catch (e) {
      console.log(e);
      setErrorMessage(e.message);
      setShowError(true);
    }
  };

  const handleSubmitLogin = async (values, { setSubmitting }) => {
    setIsLoading(true);
    const user = {
      email: values.email,
      password: values.password,
      client: "WELCOME",
    };

    await handleLogin(user);
    setSubmitting(false);
    setIsLoading(false);
  };

  console.log();
  return (
    <Formik
      initialValues={{
        id: "",
        role: 1,
        email: "",
        password: "",
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email("Invalid email address`")
          .required("This is a required field."),
        password: Yup.string()
          .required("This is a required field.")
          .min(6, "Your password is too short."),
      })}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmitLogin}
    >
      <Form className={classes.inner}>
        <TextInput
          label="Email"
          name="email"
          type="email"
          // placeholder="Enter your Email here"
        />
        <TextInput
          label="Password"
          name="password"
          type={showPassword ? "text" : "password"}
          // placeholder="Enter your Password here"
          onClick={handleShowPassword}
          showPwd={showPassword}
        />

        <div
          className={classes.forgetPassword}
          onClick={() => {
            setErrorMessage("");
            setShowError(false);
            navigate(`/auth?mode=forgot-password&id=${id}`);
          }}
        >
          Forget Password?
        </div>
        <div className={classes.bottom}>
          <Button
            type="submit"
            text="Sign in"
            customWidth="100%"
            customMargin={"85px auto 0"}
          />
        </div>
      </Form>
    </Formik>
  );
};

export default SignInForm;
