import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ListForm from "../form/ListForm";
import PageLoader from "../../../common/ui/PageLoader";

// @ts-ignore
import { answersActions, RootStateAnswers } from "../../store/answers-slice";
// @ts-ignore
import { RootStateStepper, stepperActions } from "../../store/stepper-slice";

import classes from "./Quest.module.css";
import LifestyleQuestFooter from "./LifestyleQuestFooter";
import SelectionListForm from "../form/SelectionListForm";

type ChoiceObj = {
  index: number;
  label: string;
  info?: string;
};

const LifestyleQuest = () => {
  const dispatch = useDispatch();
  const stepper = useSelector((state: RootStateStepper) => state.stepper.step);
  const lifeStyleFormData = useSelector(
    (state: RootStateStepper) => state.stepper.lifeStyleFormData
  );
  const totalSteps = useSelector(
    (state: RootStateStepper) => state.stepper.lifeStyleTotalSteps
  );
  const answers = useSelector(
    (state: RootStateAnswers) => state.answers.lifestyleQuest
  );
  const [isLoading, setIsLoading] = useState(false);

  const curAnswer = answers.myAnswers[lifeStyleFormData[stepper - 1].category];
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      answersActions.updateLifestyleAnswers({
        [lifeStyleFormData[stepper - 1].category]: +e.target.value,
      })
    );
  };

  useEffect(() => {
    // save answers to local storage

    const json = JSON.stringify(answers.myAnswers);
    localStorage.setItem("notes", json);
  }, [answers.myAnswers]);

  function updateUserSelections(option: number, userSelections: number) {
    // Check if the option is already selected
    if ((userSelections & option) === option) {
      // Option is selected, so remove it using XOR
      userSelections ^= option;
    } else {
      // Option is not selected, so add it using OR
      userSelections |= option;
    }

    dispatch(
      answersActions.updateLifestyleAnswers({
        [lifeStyleFormData[stepper - 1].category]: userSelections,
      })
    );
  }

  const handleQuestContent = (type: string) => {
    if (type === "list") {
      return (
        <>
          {lifeStyleFormData[stepper - 1].choices.map((choice: ChoiceObj) => (
            <ListForm
              key={choice.index}
              data={choice}
              value={choice.index}
              category={lifeStyleFormData[stepper - 1].category}
              answer={curAnswer}
              checked={curAnswer === choice.index}
              onChange={handleChange}
              measurement_unit={answers.myAnswers.mass_measurement_unit}
              labelDescription={false}
            />
          ))}
        </>
      );
    }

    if (type === "selection-list") {
      return (
        <>
          {lifeStyleFormData[stepper - 1].choices.map((choice: ChoiceObj) => (
            <SelectionListForm
              key={choice.index}
              data={choice}
              selections={curAnswer}
              updateUserSelections={updateUserSelections}
            />
          ))}
        </>
      );
    }
  };

  return (
    <div className={classes.container}>
      {isLoading && <PageLoader />}
      {!isLoading && (
        <div className={classes.inner}>
          <div className={classes.footerMobile}>
            <LifestyleQuestFooter setIsLoading={setIsLoading} />
          </div>
          <div className={classes.stepper}>
            {stepper}/{totalSteps}
          </div>

          <div className={classes.label}>
            {lifeStyleFormData[stepper - 1].question}
          </div>
          <div className={classes.options}>
            {handleQuestContent(lifeStyleFormData[stepper - 1].type)}
          </div>
          <div className={classes.footerWeb}>
            <LifestyleQuestFooter setIsLoading={setIsLoading} />
          </div>
        </div>
      )}
    </div>
  );
};

export default LifestyleQuest;
