import {configureStore} from "@reduxjs/toolkit";

import stepperSlice from "./stepper-slice";
import answersSlice from "./answers-slice";


const store = configureStore({
  reducer: {
    stepper: stepperSlice.reducer,
    answers: answersSlice.reducer,

  },
});

export default store;
