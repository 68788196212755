import Button from "../../../common/ui/Button";
import ProgressBar from "../../../common/ui/ProgressBar";

import classes from "./LifestyleFooter.module.css";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootStateStepper, stepperActions } from "../../store/stepper-slice";
import { answersActions, RootStateAnswers } from "../../store/answers-slice";

import { useState } from "react";
import LifestyleProgressBar from "../../../common/ui/LifestyleProgressBar";
import {
  submitBiometricQuestionnaire,
  submitLifestyleQuestionnaire,
} from "../../../api";

type Props = {
  setIsLoading: (value: boolean) => void;
};
const LifestyleQuestFooter = ({ setIsLoading }: Props) => {
  const dispatch = useDispatch();
  const stepper = useSelector((state: RootStateStepper) => state.stepper.step);
  const formData = useSelector(
    (state: RootStateStepper) => state.stepper.lifeStyleFormData
  );
  const totalSteps = useSelector(
    (state: RootStateStepper) => state.stepper.lifeStyleTotalSteps
  );
  const answers = useSelector(
    (state: RootStateAnswers) => state.answers.lifestyleQuest
  );

  const progress = (stepper * 100) / totalSteps;

  const navigate = useNavigate();

  // const handleSubmitBiometricQuestionnairePlatform = async () => {
  //   try {
  //     const infoJSON = localStorage.getItem("Info");
  //     const info = JSON.parse(infoJSON);
  //     const {
  //       cardio_training_level,
  //       fat_loss_intensity,
  //       fat_mass_target,
  //       muscle_mass_target,
  //       interval_training_level,
  //       resistance_training_level,
  //       ...PlatformAnswers
  //     } = answers.myAnswers;
  //
  //     const dataForPlatform = { ...PlatformAnswers, ...info };
  //
  //     let responsePlatform = await submitBiometricQuestionnaireToPlatform(
  //       dataForPlatform
  //     );
  //     // console.log(
  //     //   "RESPONSE SUBMIT TO MANOS",
  //     //   dataForPlatform,
  //     //   responsePlatform
  //     // );
  //   } catch (e) {
  //     console.log(e.response);
  //   }
  // };

  // const sendResponseFailed = async (errorResponse) => {
  //   try {
  //     // console.log(errorResponse);
  //     const infoJSON = localStorage.getItem("Info");
  //     const info = JSON.parse(infoJSON);
  //     const {
  //       cardio_training_level,
  //       fat_loss_intensity,
  //       fat_mass_target,
  //       muscle_mass_target,
  //       interval_training_level,
  //       resistance_training_level,
  //       ...manosAnswers
  //     } = answers.myAnswers;
  //
  //     const dataForMano = { ...manosAnswers, ...info, error: errorResponse };
  //
  //     // console.log("TEST RESPONSE FAIL", JSON.stringify(dataForMano));
  //
  //     let responseManos = await responseFail(dataForMano);
  //     // console.log("RESPONSE FAIL", dataForMano, responseManos);
  //   } catch (e) {
  //     console.log("MANOS FAIL", e);
  //   }
  // };

  const handleSubmitLifestyleQuestionnaire = async () => {
    try {
      setIsLoading(true);

      const { smoking_status, focus_issues, kcal_intake_limit, ...answerRest } =
        answers.myAnswers;

      const finalAnswers = {
        smoking_status: smoking_status === 1,
        focus_issues: focus_issues === 1,
        kcal_intake_limit: kcal_intake_limit === 1,
        ...answerRest,
      };

      console.log(finalAnswers);
      let response = await submitLifestyleQuestionnaire(finalAnswers);
      navigate("/success-lifestyle");
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("notes");
      localStorage.removeItem("Info");

      setIsLoading(false);
    } catch (e) {
      // const error = e;
      //
      // const errorObj = {
      //   canonical_name: e.response.data.http_error_code.canonical_name,
      //   message: e.response.data.message,
      //   status: e.response.status,
      // };
      //
      // await sendResponseFailed(JSON.stringify(errorObj));

      dispatch(stepperActions.handleResponseStatus(e.response.status));
      // // console.log("FAILED TO MAKIS", JSON.stringify(e.response));
      setIsLoading(false);
      navigate("/error");
    }
  };

  const nextStep = async () => {
    if (stepper >= totalSteps) {
      await handleSubmitLifestyleQuestionnaire();
    } else {
      dispatch(stepperActions.next());
    }
  };
  const prevStep = () => {
    stepper > 1 && dispatch(stepperActions.prev());
  };

  const handleNextIsDisabled = () => {
    if (formData[stepper - 1].type === "list") {
      return answers.myAnswers[formData[stepper - 1].category] === "";
    }

    if (formData[stepper - 1].type === "selection-list") {
      if (
        formData[stepper - 1].category === "dietary_restrictions" ||
        formData[stepper - 1].category === "medical_conditions"
      ) {
        return false;
      } else {
        return answers.myAnswers[formData[stepper - 1].category] === 0;
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.inner}>
        <Button text="Back" onClick={prevStep} isDisabled={stepper === 1} />
        <LifestyleProgressBar value={progress} />
        <Button
          text="Next"
          onClick={nextStep}
          isDisabled={handleNextIsDisabled()}
        />
      </div>
      <div className={classes.innerMobile}>
        <div className={classes.stepper}>
          {stepper !== 1 && (
            <button
              className={classes.iconContainer}
              onClick={prevStep}
              disabled={stepper === 1}
            >
              <svg
                width="12"
                height="18"
                viewBox="0 0 12 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={classes.icon}
              >
                <path
                  d="M9.37464 17.4375C8.92712 17.4374 8.49797 17.2595 8.18158 16.943L1.43158 10.193C1.11522 9.87659 0.9375 9.44745 0.9375 8.99998C0.9375 8.55252 1.11522 8.12337 1.43158 7.80692L8.18158 1.05692C8.33724 0.895748 8.52345 0.767191 8.72933 0.678751C8.93521 0.590311 9.15665 0.543759 9.38071 0.541812C9.60478 0.539865 9.82699 0.582561 10.0344 0.66741C10.2418 0.752259 10.4302 0.877561 10.5886 1.03601C10.7471 1.19445 10.8724 1.38286 10.9572 1.59025C11.0421 1.79764 11.0848 2.01985 11.0828 2.24391C11.0809 2.46798 11.0343 2.68941 10.9459 2.89529C10.8574 3.10117 10.7289 3.28738 10.5677 3.44305L5.01076 8.99998L10.5677 14.5569C10.8036 14.7929 10.9643 15.0936 11.0294 15.4209C11.0945 15.7482 11.061 16.0874 10.9333 16.3957C10.8057 16.704 10.5894 16.9675 10.312 17.153C10.0345 17.3384 9.70834 17.4374 9.37464 17.4375Z"
                  fill="#E6F1FF"
                />
              </svg>
            </button>
          )}
          <div className={classes.header}>
            <div>
              {stepper}/{totalSteps}
            </div>
            <div className={classes.titleMobile}>LIFESTYLE</div>
          </div>
        </div>
        <LifestyleProgressBar value={progress} />
      </div>
      <div className={classes.bottom}>
        <Button
          text="Next"
          customWidth="100%"
          onClick={nextStep}
          isDisabled={handleNextIsDisabled()}
        />
      </div>
    </div>
  );
};
export default LifestyleQuestFooter;
