export const COMPANY_MAIL_WITH_CUSTOM_REPORT = [
  "paul.gualano@equinox.com",
  "alanna.bradley@equinox.com",
  "matt.potter@equinox.com",
  "brandon.russell@equinox.com",
  "equinoxla1@equinox.com",
  "equinoxny1@equinox.com",
  "jess.bieda@equinox.com",
  "casey.sheppard@equinox.com",
  "evan.velez@equinox.com",
  "sean.coll@equinox.com",
  "pnoe.ehyny@gmail.com",
  "eqx137.pnoe@equinox.com",
  "ross.rubin@equinox.com",
];

export const METRIC_TYPE = {
  RMR: 2,
  FATBURN_EFFICIENCY: 3,
  HEART_FITNESS: 4,
  LUNG_FITNESS: 5,
  BREATHING_COGNITION: 6,
  METABOLIC_DYSFUNCTION_RISK: 8,
  AEROBIC_CAPACITY: 9,
  CARDIOVASCULAR_FITNESS: 10,
  METABOLIC_RATE: 11,
  RESPIRATORY_CAPABILITY: 12,
  RESPIRATORY_CAPACITY: 13,
  RESPIRATORY_COORDINATION: 15,
  MOVEMENT_ECONOMY: 16,
  RECOVERY_CAPACITY: 18,
  BREATHING_STABILITY: 19,
  BREATH_SCORE: 20,
  PSYCHOLOGICAL_STRESS: 21,
  PHYSIOLOGICAL_STRESS: 22,
  TRAINING_ZONES: 23,
  OXYGEN_DELIVERY: 24,
  SDNN: 25,
  VENTILATION_EFFICIENCY: 26,
};
