import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Footer from "./Footer";
import CustomDatePicker from "../form/CustomDatePicker";
import BtnForm from "../form/BtnForm";
import ListForm from "../form/ListForm";
import ScaleForm from "../form/ScaleForm";
import DoubleScaleForm from "../form/DoubleScaleForm";
import PageLoader from "../../../common/ui/PageLoader";

import { answersActions } from "../../store/answers-slice";
import { stepperActions } from "../../store/stepper-slice";

import classes from "./Quest.module.css";

const Quest = ({ flexType, scaleType, labelDescription }) => {
  const dispatch = useDispatch();
  const stepper = useSelector((state) => state.stepper.step);
  const formData = useSelector((state) => state.stepper.formData);
  const totalSteps = useSelector((state) => state.stepper.totalSteps);
  const answers = useSelector((state) => state.answers.biometricQuest);
  const [isLoading, setIsLoading] = useState(false);

  const curAnswer = answers.myAnswers[formData[stepper - 1].category];

  const handleChange = (e) => {
    if (formData[stepper - 1].category === "mass_measurement_unit") {
      dispatch(stepperActions.handleConvert());
      dispatch(
        answersActions.updateBiometricAnswers({
          length_measurement_unit: +e.target.value,
        })
      );
    }
    dispatch(
      answersActions.updateBiometricAnswers({
        [formData[stepper - 1].category]: +e.target.value,
      })
    );
  };

  useEffect(() => {
    // save answers to local storage

    const json = JSON.stringify(answers.myAnswers);
    localStorage.setItem("notes", json);
  }, [answers.myAnswers]);

  const handleQuestContent = (type) => {
    if (type === "datepicker") {
      return <CustomDatePicker />;
    }
    if (type === "btn") {
      return (
        <>
          {formData[stepper - 1].choices.map((choice) => (
            <BtnForm
              key={choice.index}
              data={choice}
              value={choice.index}
              category={formData[stepper - 1].category}
              answer={curAnswer}
              checked={curAnswer === choice.index}
              onChange={handleChange}
            />
          ))}
        </>
      );
    }
    if (
      (type === "scale" && answers.myAnswers.mass_measurement_unit === 1) ||
      ((formData[stepper - 1].category === "current_weight" ||
        formData[stepper - 1].category === "target_weight") &&
        answers.myAnswers.mass_measurement_unit === 2)
    ) {
      return <ScaleForm scaleType={scaleType} />;
    }

    if (type === "scale" && answers.myAnswers.mass_measurement_unit === 2) {
      return <DoubleScaleForm scaleType={scaleType} />;
    }

    if (type === "list") {
      return (
        <>
          {formData[stepper - 1].choices.map((choice) => (
            <ListForm
              key={choice.index}
              data={choice}
              value={choice.index}
              category={formData[stepper - 1].category}
              answer={curAnswer}
              checked={curAnswer === choice.index}
              onChange={handleChange}
              measurement_unit={answers.myAnswers.mass_measurement_unit}
              labelDescription={labelDescription}
            />
          ))}
        </>
      );
    }
  };

  return (
    <div className={classes.container}>
      {isLoading && <PageLoader />}
      {!isLoading && (
        <div>
          <div className={classes.inner}>
            <div className={classes.footerMobile}>
              <Footer setIsLoading={setIsLoading} />
            </div>
            <div className={classes.stepper}>
              {stepper <= 10 ? stepper : stepper - 1}/
              {totalSteps <= 10 ? totalSteps : totalSteps - 1}
            </div>

            <div className={classes.label}>
              {formData[stepper - 1].question}
            </div>
            <div
              className={classes.options}
              style={{ flexDirection: flexType }}
            >
              {handleQuestContent(formData[stepper - 1].type)}
            </div>
            <div className={classes.footerWeb}>
              <Footer setIsLoading={setIsLoading} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Quest;
